import React from "react";
import PropTypes from "prop-types";
import plus from "../../../public/images/plus.png";
import deleteIcon from "../../../public/images/delete.svg";

const AddCategoriesSection = ({
  setNewCategoryFormIsDisplayed,
  setChosenCategories,
  setValues,
  setNewCategory,
  existingCategories,
  newCategory,
  newCategoryFormIsDisplayed,
  chosenCategories,
  values,
}) => {
  const handleClickAddIcon = () => {
    // setNewCategoryFormIsDisplayed(true);
    window.location.href = "/admin/categories";
  };
  const handleClickChooseCategory = (e) => {
    const id = parseInt(e.target.id, 10);
    const chosenCategory = existingCategories.filter(
      (category) => category.id === id
    );
    setChosenCategories([...chosenCategories, chosenCategory[0]]);
    setValues(() => {
      const updatedCategories = values.categories;
      updatedCategories.push(id);
      return {
        ...values,
        categories: updatedCategories,
      };
    });
  };
  const deleteChosenCategory = (e, index) => {
    // on identifie la catégorie supprimée par son id ou son nom
    const removedCategoryId = parseInt(chosenCategories[index].id, 10);
    const removedCategoryName = chosenCategories[index].name;
    // on met à jour notre tableau de catégories affichées en supprimant la catégorie choisie
    const updatedChosenCategories = chosenCategories;
    updatedChosenCategories.splice(index, 1);
    setChosenCategories(updatedChosenCategories);
    // on va mettre à jour l'objet values du state qui contient categories et new_categories
    let newCategoriesArray;
    // si la catégorie supprimée est une catégorie créée par l'utilisateur, on l'enlève de new_categories
    if (Number.isNaN(removedCategoryId)) {
      newCategoriesArray = values.new_categories.filter(
        (element) => element.name !== removedCategoryName
      );
    } else {
      // si la catégorie supprimée est une catégorie déjà existante, on la supprime du tableau catégories
      newCategoriesArray = values.categories.filter(
        (element) => element !== removedCategoryId
      );
    }
    setValues(() => {
      // maintenant qu'on a stocké le tableau de catégories à jour, on l'attribue à la bonne propriété
      const updatedCategories = Number.isNaN(removedCategoryId)
        ? values.categories
        : newCategoriesArray;
      const updatedNewCategories = Number.isNaN(removedCategoryId)
        ? newCategoriesArray
        : values.new_categories;
      return {
        ...values,
        categories: updatedCategories,
        new_categories: updatedNewCategories,
      };
    });
  };
  const handleChangeNewCategory = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };
  const handleSubmitNewCategory = (e) => {
    e.preventDefault();
    setChosenCategories([...chosenCategories, newCategory]);
    setNewCategory({ name: "", color: "" });
    setValues(() => {
      const newCategories = values.new_categories;
      newCategories.push(newCategory);
      return {
        ...values,
        new_categories: newCategories,
      };
    });
  };
  return (
    <section className="addCategories">
      <div className="addAnimationForm">
        <h2 className="tutoAdmin">Ajouter des catégories</h2>
      </div>
      <p>Choisir parmi les catégories existantes :</p>
      <div className="existingCategories">
        {existingCategories.map((category) => (
          <button
            type="button"
            className="buttonCategory"
            style={{ backgroundColor: `#${category.color}` }}
            key={category.id}
            id={category.id}
            onClick={handleClickChooseCategory}
          >
            {category.name.toUpperCase()}
          </button>
        ))}
      </div>
      <div className="addNewCategoryButton" onClick={handleClickAddIcon}>
        <p>Ou en créer de nouvelles :</p>
        <img src={plus} alt="Icone Plus" />
      </div>
      <form
        className={newCategoryFormIsDisplayed ? "addNewCategoryForm" : "hidden"}
        onSubmit={handleSubmitNewCategory}
      >
        <div className="formRow">
          <label htmlFor="firstname">Nom de la catégorie :</label>
          <input
            type="text"
            id="firstname"
            name="name"
            placeholder="Entrer le nom de la catégorie"
            value={newCategory.name}
            onChange={handleChangeNewCategory}
          />
        </div>
        <div className="formRow">
          <label htmlFor="color">
            Entrer l&apos;hexadécimal d&apos;une couleur :
          </label>
          <div
            className="colorPreview"
            style={{
              backgroundColor:
                newCategory.color.length === 6 ? `#${newCategory.color}` : "",
            }}
          />
          <input
            type="text"
            id="color"
            name="color"
            maxLength="6"
            placeholder="Entrer un code à 6 chiffres"
            value={newCategory.color}
            onChange={handleChangeNewCategory}
          />
        </div>
        <button type="submit" className="submitButton">
          Créer la catégorie
        </button>
      </form>
      <p>Catégories sélectionnées pour l&apos;article :</p>
      <div className="selectedCategories">
        {chosenCategories.map((category, index) => (
          <button
            key={category.name}
            type="button"
            className="buttonCategory"
            style={{ backgroundColor: `#${category.color}` }}
          >
            {category.name.toUpperCase()}
            <button
              type="button"
              className="deleteCategory"
              onClick={(e) => deleteChosenCategory(e, index)}
            >
              <img src={deleteIcon} alt="supprimer la catégorie" />
            </button>
          </button>
        ))}
      </div>
    </section>
  );
};

AddCategoriesSection.propTypes = {
  existingCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  newCategory: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  newCategoryFormIsDisplayed: PropTypes.bool.isRequired,
  chosenCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  setChosenCategories: PropTypes.func.isRequired,
  setNewCategory: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setNewCategoryFormIsDisplayed: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.object),
    media: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.number),
    new_categories: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default AddCategoriesSection;
