/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./trophee.css";
import axios from "axios";
import Footer from "../../user/Footer";
import Menu from "../../user/Menu";

const API_URL = process.env.REACT_APP_API_URL;

// import { Switch, Route } from "react-router-dom";

let isClicked = false;

function Trophee() {
  const [textDispayed, setTextDispayed] = useState([
    { id: "0", content: "" },
    { id: "1", content: "" },
  ]);
  const [mediaDisplayed, setMediaDisplayed] = useState();
  const [concourList, setconcourList] = useState([]);
  const [idToSend, setIdToSend] = useState();

  // console.log(JSON.stringify(concourList));

  useEffect(() => {
    axios
      .get(`${API_URL}/api/trophees/`)
      .then((result) => result.data)
      .then((data) => {
        setconcourList(data);
      });
  }, []);

  const handleSelect = () => {
    if (!idToSend) return
    
    axios
      .get(`${API_URL}/api/trophees/${idToSend}`)
      .then((result) => result.data)
      .then((data) => {
        setTextDispayed(data.texts);
        setMediaDisplayed(data.media.name);
      });
    isClicked = true;
  };

  return (
    <div className="globalContainer p-1">
      <Helmet>
        <title>Trophées - Planète Sciences Sarthe</title>
      </Helmet>
      <div className="upContainer">
        <Menu />
      </div>
      <div className="bottomContainer">
        <div className="leftSection m-5">
          {mediaDisplayed && (
            <video
              key={mediaDisplayed}
              width={window.window.screen.width > 800 ? 500 : 380}
              height={window.window.screen.width > 800 ? 300 : 280}
              controls
            >
              <source src={`${API_URL}/videos/${mediaDisplayed}`} type="video/mp4" />
              vidéo de présentation
            </video>
          )}
        </div>
        <div className="rightSection">
          <div>
            <div className="mt-5">
              <p >
                Les trophées de robotique changent de nom : ils s'appellent désormais Coupe de France de robotique junior.
                <br />
                Ils sont coorganisés par Planète Sciences Sarthe et les Francas 49, en partenariat avec la ville d'Angers.
              </p>
            </div>
            <h2 className="mt-5 text-center">Sélectionner un trophée puis cliquez sur trouver :</h2>
            <div className="tropheeSelector">
              <select className="selectHeight"
                onChange={(event) => {
                  setIdToSend(event.target.value);
                }}
              >
                <option>Choisir un Trophée et cliquer sur trouver</option>
                {concourList.map((concour) => (
                  <option value={concour.id} key={concour.id}>
                    {concour.name} / {concour.year} / {concour.category}
                  </option>
                ))}
              </select>
              <button
                className="tropheeSelectorButton"
                type="button"
                onClick={handleSelect}
              >
                {" "}
                Trouver{" "}
              </button>
            </div>
            <div className="texteContainer">
              {textDispayed.map((texte, i) => (
                <div key={texte.id} className="texteTrophee">
                  {i === 0 && isClicked === true &&
                    <div className="d-flex justify-content-center">
                      <div className="tropheebutton mb-4" type="button">
                        Présentation
                      </div>
                    </div>
                  }
                  {i === 1 && isClicked === true &&
                    <div className="d-flex justify-content-center">
                      <div className="tropheebutton mb-4" type="button">
                        Objectif et règles
                      </div>
                    </div>
                  }
                  {i === 2 && isClicked === true &&
                    <div className="d-flex justify-content-center">
                      <div className="tropheebutton mb-4" type="button">
                        Horaires et lieux
                      </div>
                    </div>
                  }
                  <p className="ml-2">{texte.content}</p>
                </div>
              ))}
              {window.screen.width > 800 ? "" : <Footer />}
            </div>
          </div>
        </div>
      </div>
      {window.screen.width > 800 ? <Footer /> : ""}
    </div>
  );
}

export default Trophee;
