import React from "react";
import PropTypes from "prop-types";
import "./adminAnimation.css";
import { NavLink } from "react-router-dom";
import modif from "../../../public/images/modif.png";
import suppr from "../../../public/images/suppr.png";

const API_URL = process.env.REACT_APP_API_URL;

const ExistingAnimation = ({
  id,
  title,
  subtitle,
  date,
  backgroundImage,
  handleClickDelete,
}) => {
  return (
    <div className="existingAnimation">
      <div className="animationHeader">
        <h4 className="alignLeft" >{title}</h4>
        <NavLink className="noColorHover" to={`/admin/modify-animations/${id}`}>
          <div className="adminModification">
            <p>Modifier</p>
            <img className="LogoIcon" alt="Icone de modification" src={modif} />
          </div>
        </NavLink>
        <span className="pointer animSuppr ml-5" onClick={() => handleClickDelete(id)}>
          <p className="d-inline-block">Supprimer</p>
          <img
            className="LogoIcon d-inline-block"
            alt="logoSuppr"
            src={suppr}
          />
        </span>
      </div>
      <div className="animationBody">
        <div className="animationImage">
          <img
            src={`${API_URL}/images/${backgroundImage.name}`}
            alt={backgroundImage.alt}
          />
        </div>
        <div className="animationInfo">
          <h4>Sous-titre : {subtitle}</h4>
          <p>écrit le : {date}</p>
        </div>
      </div>
    </div>
  );
};

ExistingAnimation.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  backgroundImage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  handleClickDelete: PropTypes.func.isRequired,
};

export default ExistingAnimation;
