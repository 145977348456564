import React from "react";
import { NavLink } from "react-router-dom";
import closeImg from "../../../public/images/512px-Gnome-window-close.svg.png";
import logoburger from "../../../image/unnamed.png";
import Dons from "../../components/Shared/Dons";

class NavbarMobile extends React.Component {
  state = {
    isOpen: false,
  };

  openMenu = () => {
    this.setState({ isOpen: true });
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    return (
      <div>
        <div id={isOpen ? "myNav-open" : "myNav-close"} className="overlay">
          <input
            type="image"
            className="closebtn pr-3"
            onClick={this.closeMenu}
            alt="Menu"
            src={closeImg}
          />

          <div id={isOpen ? "overlay-content" : "overlay-none"}>
            <button type="button" className="buttonnavmobile">
              <NavLink to="/form">
                <p className="text-white pt-2">Nous Contacter</p>
              </NavLink>
            </button>
            {/* <button type="button" className="buttonnavmobile">
              Faire un Don
            </button> */}
            <div className="ml-5">
              <Dons />
            </div>
          </div>
        </div>

        <input
          type="image"
          className="burger"
          alt="Menu"
          src={logoburger}
          onClick={this.openMenu}
        />
      </div>
    );
  }
}

export default NavbarMobile;
