import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./SousMenuActivités.css";
import axios from "axios";
import terre from "../../../public/images/accueil/earth.png";
import satellite from "../../../public/images/satellite.png";
import asteroïd from "../../../public/images/asteroïd.png";
import lune from "../../../public/images/moon4.png";

function SousMenuActivités() {
  const [activités, setActivités] = useState([
    { id: 0, title: "" },
    { id: 0, title: "" },
    { id: 0, title: "" },
  ]);
  const API_URL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    axios.get(`${API_URL}/api/en_tete`).then((res) => setActivités(res.data));
  }, []);
  return (
    <div className="sousMenuActiv">
      <NavLink
        activeClassName="active"
        to={`/page-activité/${activités[0].id}`}
        className="PourVotreEnfantL"
      >
        <div className="PourVotreEnfant placementTexteLune">
          <img
            src={lune}
            alt="PourVotreEnfantL"
            className="imagePourVotreEnfant"
          />
          <div className="textePourVotreEnfant">
            <p className="PourVotreEnfantTXT">{activités[0].title}</p>
          </div>
        </div>
      </NavLink>
      <div className="TerreLogoL">
        <img src={terre} alt="TerreLogo" className="TerreLogo" />
        <div className="texteLogoT">
          <p>NOS ACTIVITÉS PROPOSÉES</p>
        </div>
      </div>
      <NavLink
        activeClassName="active"
        to={`/page-activité/${activités[1].id}`}
        className="PourVotreEtablissementOuAssociationL"
      >
        <div className="PourVotreEtablissementOuAssociationActiv">
          <img
            src={satellite}
            alt="PourVotreEtablissementOuAssociation"
            className="imagePourVotreEtablissementOuAssociation"
          />
          <div className="textePourVotreEtablissementOuAssociation">
            <p className="PourVotreEtablissementOuAssociationTXT">
              {activités[1].title}
            </p>
          </div>
        </div>
      </NavLink>
      <NavLink
        activeClassName="active"
        to={`/page-activité/${activités[2].id}`}
        className="PourVotreCentreDeLoisirOuCollectivitéesL"
      >
        <div className="PourVotreCentreDeLoisirOuCollectivitées">
          <img
            src={asteroïd}
            alt="PourVotreCentreDeLoisirOuCollectivitées"
            className="imageAsteroActiv"
          />
          <div className="textePourVotreCentreDeLoisirOuCollectivitées">
            <p className="PourVotreCentreDeLoisirOuCollectivitéesTXT">
              {activités[2].title}
            </p>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default SousMenuActivités;
