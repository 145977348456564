import React from "react";
import ListOfCategories from "./ListOfCategories";
import AddCategoriesForm from "./AddCategoriesForm";

const PageCategories = () => {
  return (
    <div className="AdminComp">
      <ListOfCategories />
      <AddCategoriesForm />
    </div>
  );
};

export default PageCategories;
