import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "../AdminAPP.css";
import ListPartenaires from "./ListPartenaires";
import AddPartenaire from "./AddPartenaire";
import AffichagePartenaires from "./AffichagePart/AffichagePartenaires";
import AddPartenaireForm from "./AddPartenaireForm";
import getAuthorizationHeaders from "../../services/getAuthorizationHeaders";

const API_URL = process.env.REACT_APP_API_URL;

function AdminComp({ removeTokenAndLogout }) {
  const [isOpen, setIsOpen] = useState(false);
  const [partenaires, setPartenaires] = useState([]);
  const url = `${API_URL}/api/partenaires`;

  const headers = getAuthorizationHeaders();

  const AddNvPart = () => {
    setIsOpen(!isOpen);
  };

  const getPartenaires = () => {
    axios
      .get(url)
      .then((result) => result.data)
      .then((data) => {
        setPartenaires(data);
      });
  };

  const deleteClick = (id) => {
    const validation = confirm(
      "Etes-vous certain de vouloir supprimer ce partenaire ?"
    );
    if (validation) {
      axios
        .delete(`${url}/${id}`, headers)
        .then(() => {
          getPartenaires();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            alert(err.response.data.errorMessage);
          } else {
            alert("Vous devez vous reconnecter");
            removeTokenAndLogout();
          }
        });
    }
  };

  const addPartenaire = (data) => {
    axios
      .post(`${API_URL}/api/partenaires`, data, headers)
      .then(() => {
        getPartenaires();
        setIsOpen(false);
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          alert(err.response.data.errorMessage);
        } else {
          alert("Vous devez vous reconnecter");
          removeTokenAndLogout();
        }
      });
  };

  useEffect(() => {
    getPartenaires();
  }, []);

  return (
    <div className="AdminComp">
      <div className="Card">
        {partenaires.length > 0 && (
          <ListPartenaires
            removeTokenAndLogout={removeTokenAndLogout}
            partenaires={partenaires}
            deleteClick={deleteClick}
          />
        )}
      </div>
      <AddPartenaire AddNvPart={AddNvPart} />
      {isOpen && <AddPartenaireForm addPartenaire={addPartenaire} />}
      <div className="bcg-img">
        {partenaires.length > 0 && (
          <AffichagePartenaires partenaires={partenaires} />
        )}
      </div>
    </div>
  );
}

AdminComp.propTypes = {
  removeTokenAndLogout: PropTypes.func.isRequired,
};

export default AdminComp;
