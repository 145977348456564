/* eslint-disable react/button-has-type */
import React from "react";
import avatar from "../../public/images/avatar.jpeg";
import "./AdminAPP.css";

function BvnBar() {
  function logout() {
    alert("Vous êtes maintenant déconnecté");
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <div className="TopBar">
      <button onClick={logout} className="btn btn-danger mr-5">
        Déconnexion
      </button>
      <h1 className="texteBvn">Bienvenue admin</h1>
      <img className="imageAvatar" src={avatar} alt="imgAvatar" />
    </div>
  );
}

export default BvnBar;
