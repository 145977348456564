import React, { useState } from "react";
import axios from "axios";
import getAuthorizationHeaders from "../../services/getAuthorizationHeaders";

const API_URL = process.env.REACT_APP_API_URL;

const AddCategoriesForm = () => {
  const [newCategory, setNewCategory] = useState({ name: "", color: "#737373" });
  const [feedbackMessage, setFeedbackMessage] = useState("");

  function handleChangeNewCategory(e) {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };
  function changeColor(e) {
    setNewCategory({ ...newCategory, color: e.target.value });
    // let inputColor = document.getElementById("color");
    // let selectorColor = document.getElementById("head");
    // console.log(e.target.value, e.target.name);
    // selectorColor.value = color;
    // color = color.substring(1);
    // inputColor.value = color;
    // handleChangeNewCategoryColor(inputColor);
  }

  function reload() {
    setFeedbackMessage("La catégorie a bien été créée");
    alert("La catégorie a bien été créée");
    window.location.reload();
  }

  const handleSubmitNewCategory = (e) => {
    const headers = getAuthorizationHeaders();
    e.preventDefault();
    if (newCategory.name !== "" && newCategory.color !== "") {
      const payload = {
        name: newCategory.name,
        color: newCategory.color.includes("#") ? newCategory.color.substring(1) : newCategory.color,
      }

      const url = `${API_URL}/api/categories`;
      axios
        .post(url, payload, headers)
        .then(reload())
        .catch((err) => {
          console.error(err);
          setFeedbackMessage(
            "un problème avec le serveur est survenu. Veuillez contacter l'équipe de développement"
          );
        });
    } else {
      setFeedbackMessage("Veuillez remplir le champ et sélectionner une couleur");
    }
  };

  return (
    <div className="AdminComp">
      <h2>Créer une nouvelle catégorie :</h2>
      <form className="addNewCategoryForm mx-auto" onSubmit={handleSubmitNewCategory}>
        <div className="formRow">
          <label htmlFor="firstname">Nom de la catégorie :</label>
          <input
            type="text"
            id="firstname"
            name="name"
            placeholder="Nom de la catégorie"
            value={newCategory.name}
            onChange={handleChangeNewCategory}
            required
          />
        </div>
        <div className="formRow">
          <label htmlFor="color">
            Cliquez sur le cercle pour <br></br>
            choisir une couleur ou <br></br> entrez son code hexadécimal (ex : <b>#737373</b>):
          </label>
          <div>
            <input type="color" id="head" name="head" className="styleCategColor"
              value={newCategory.color} onChange={e => changeColor(e)} />
            <label htmlFor="head"></label>
            <div
              className="colorPreview"
              style={{
                backgroundColor: newCategory.color,
              }}
            />
          </div>

          <input
            className="mt-4"
            type="text"
            id="color"
            name="color"
            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
            placeholder="737373"
            value={newCategory.color}
            onChange={handleChangeNewCategory}
            required
          />
        </div>
        <button type="submit" className="submitButton">
          Créer la catégorie
        </button>
        <p>{feedbackMessage}</p>
      </form>
    </div>
  );
};

export default AddCategoriesForm;
