import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const API_URL = process.env.REACT_APP_API_URL;

function useInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    const id = setInterval(tick, 3000);
    return () => clearInterval(id);
  }, [delay]);
}

export const SectionAnimation = ({ animation }) => {
  const [aLaLigne, setALaLigne] = useState(0);
  const [selectedIndex, setselectedIndex] = useState({
    previous1: 3,
    previous2: 4,
    current: 0,
    next1: 1,
    next2: 2,
  });
  let charCount = [0, 0];
  useEffect(() => {
    animation.texts.forEach((text, index) => {
      if (charCount[0] <= 850) {
        charCount = [text.content.length + charCount[0], index];
        if (index === animation.texts.length - 1) {
          setALaLigne(100);
        }
      } else {
        setALaLigne(charCount[1]);
      }
    });
  }, [animation]);
  const determineClass = (selectedI, cardIndex) => {
    if (selectedI.current === cardIndex) {
      return "active";
    }
    if (selectedI.next1 === cardIndex) {
      return "next1";
    }
    if (selectedI.next2 === cardIndex) {
      return "next2";
    }
    if (selectedI.previous1 === cardIndex) {
      return "previous1";
    }
    if (selectedI.previous2 === cardIndex) {
      return "previous2";
    }
    return "hidden";
  };
  // function that moves images of the diaporama
  const handleNext = () => {
    setselectedIndex({
      previous1:
        selectedIndex.previous1 === 0 ? 4 : selectedIndex.previous1 - 1,
      previous2:
        selectedIndex.previous2 === 0 ? 4 : selectedIndex.previous2 - 1,
      current: selectedIndex.current === 0 ? 4 : selectedIndex.current - 1,
      next1: selectedIndex.next1 === 0 ? 4 : selectedIndex.next1 - 1,
      next2: selectedIndex.next2 === 0 ? 4 : selectedIndex.next2 - 1,
    });
  };
  // custom hooks to move images every 3s
  useInterval(handleNext, 3000);
  return (
    <div className="pageContainer">
      <div className="animationTitles">
        <h1>{animation.title}</h1>
        <h2>{animation.subtitle}</h2>
      </div>
      <section className="categoriesContainer">
        {animation.categories.map((category) => (
          <div
            key={category.id}
            className="buttonCategory"
            style={{ backgroundColor: `#${category.color}` }}
          >
            {category.name.toUpperCase()}
          </div>
        ))}
      </section>
      <main className="pageBody">
        <section className="pagePreviewOfImages">
          {animation.media
            .filter((elt, index) => index > 0 && index < 6)
            .map((image, index) => (
              <button
                key={image.id}
                type="button"
                className={`btn-OurReco ${determineClass(
                  selectedIndex,
                  index
                )}`}
              >
                <img src={`${API_URL}/images/${image.name}`} alt={image.alt} />
              </button>
            ))}
        </section>
        <section className="textSection">
          {animation.texts
            .filter((t, index) => index <= aLaLigne)
            .map((text) => {
              return text.type === "h1" ? (
                <h1 key={text.id}>{text.content}</h1>
              ) : text.type === "h2" ? (
                <h2 key={text.id}>{text.content}</h2>
              ) : (
                <p key={text.id}>
                  {text.content}
                  <br />
                </p>
              );
            })}
        </section>
      </main>
      <section
        className="textSection secondBody"
        style={{ marginBottom: aLaLigne > 0 ? "70px" : "0" }}
      >
        {animation.texts
          .filter((t, index) => index > aLaLigne)
          .map((text) => {
            return text.type === "h1" ? (
              <h1 key={text.id}>{text.content}</h1>
            ) : text.type === "h2" ? (
              <h2 key={text.id}>{text.content}</h2>
            ) : (
              <p key={text.id}>
                {text.content}
                <br />
              </p>
            );
          })}
      </section>
    </div>
  );
};

SectionAnimation.propTypes = {
  animation: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.object),
    media: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
