import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import plus from "../../public/images/plus.png";

const AddHeader = ({ text, path }) => {
  return (
    <NavLink to={`/admin/${path}`}>
      <div className="AddPartenaire">
        <img src={plus} alt="Icone Plus" className="imageAdd" />
        <h1 className="AddNvPart">Ajouter {text}</h1>
      </div>
    </NavLink>
  );
};

AddHeader.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default AddHeader;
