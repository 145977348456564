import React from "react";
import { Helmet } from "react-helmet";
import Galerie from "./Galerie";
import "./Galerie.css";

function GalerieVideo() {
  return (
    <div className="galerie-videos">
      <Helmet>
        <title>Vidéos - Planète Sciences Sarthe</title>
      </Helmet>
      <h1 className="text-center fw-bold mt-5">
        Vidéos
      </h1>
      <Galerie type="image" />
    </div>
  );
}

export default GalerieVideo;
