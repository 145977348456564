import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import AddTextsSection from "../animation/AddTextsSection";
import ModifyImagesSection from "./ModifyImagesSection";
import { DeleteImagesSection } from "./DeleteImagesSection";
import DontForget from "../DontForget";

const API_URL = process.env.REACT_APP_API_URL;

const ModifyActivitésForm = ({ match }) => {
  const { id } = match.params;
  const [values, setValues] = useState({
    id: 0,
    title: "",
    texts: [],
    media: [],
    animations: [],
  });
  const [addedMedia, setAddedMedia] = useState([]);
  const [filename, setFilename] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackImage, setfeedbackImage] = useState("");
  const [type, setType] = useState("image");
  useEffect(() => {
    axios
      .get(`${API_URL}/api/en_tete/${id}`)
      .then((res) => setValues(res.data));
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  useEffect(() => {
    if (filename !== "") {
      const updatedMediaArray = addedMedia;
      const newMedium = { name: filename, alt: "", type };
      updatedMediaArray.push(newMedium);
      setAddedMedia(updatedMediaArray);
      setFilename("");
      document.getElementById("bcgImage").checked = false;
      setType("image");
    }
  }, [filename]);
  const handleSubmitTexts = () => {
    if (values.title !== "" && values.texts.length > 0) {
      axios
        .put(`${API_URL}/api/en_tete/${id}`, { title: values.title })
        .then(() => {
          setFeedbackMessage("Le contenu a bien été modifié");
        })
        .catch((err) => {
          alert(err);
          console.error(err);
        });
      axios
        .put(`${API_URL}/api/en_tete/${id}/texts`, values.texts)
        .catch((err) => alert(err));
    } else {
      setFeedbackMessage(
        "Veuillez ne pas laisser le titre vide et ajouter au moins un texte"
      );
    }
  };
  const handleSubmitMedia = () => {
    let type = addedMedia[0].type;
    if (addedMedia.length > 0) {
      axios
        .post(`${API_URL}/api/en_tete/${id}/media/${type}`, addedMedia)
        .then(() => {
          setfeedbackImage("les images on bien été enregistrées");
          window.location.reload();
        })
        .catch((err) => alert(err));
    } else {
      setfeedbackImage("Il n'y pas d'image à ajouter");
    }
  };
  return (
    <main className="AdminComp addAnimationForm">
      <input
        type="text"
        name="title"
        className="mainTitle"
        placeholder="Saisir le titre de l'animation"
        value={values.title}
        onChange={handleChange}
      />
      <div className="alignLeft">
        <AddTextsSection setValues={setValues} values={values} />
      </div>
      <button
        type="button"
        onClick={handleSubmitTexts}
        className="addTextButton"
      >
        Enregistrer la modification du titre et des textes
      </button>
      <p className="feedback">{feedbackMessage}</p>
      <div className="alignLeft">
        <ModifyImagesSection
          setAddedMedia={setAddedMedia}
          values={values}
          setFilename={setFilename}
          setType={setType}
          addedMedia={addedMedia}
        />
      </div>
      <DontForget />
      <button
        type="button"
        onClick={handleSubmitMedia}
        className="addTextButton"
      >
        Enregistrer les modifications faites sur les images
      </button>
      <p className="feedback">{feedbackImage}</p>
      <div className="alignLeft">
        <DeleteImagesSection values={values} setValues={setValues} />
      </div>
    </main>
  );
};

ModifyActivitésForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  }).isRequired,
};

export default ModifyActivitésForm;
