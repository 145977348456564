import axios from "axios";
import React from "react";
import PropTypes from "prop-types";
import deleteIcon from "../../../public/images/delete.svg";

const API_URL = process.env.REACT_APP_API_URL;

export const DeleteImagesSection = ({ values, setValues }) => {
  const handleDelete = (index) => {
    const confirmation = confirm(
      "Êtes-vous sûr de vouloir supprimer cette image ?"
    );

    if (confirmation) {
      const chosenId = values.media[index].id;
      // on enlève le media du state
      const updatedMedia = values.media;
      updatedMedia.splice(index, 1);
      setValues({ ...values, media: updatedMedia });
      // on enlève le media de la base de données
      axios
        .delete(`${API_URL}/api/media/${chosenId}`)
        .catch((err) => alert(err));
    }
  };
  return (
    <section className="addCategories">
      <div className="addAnimationForm ">
        <h2 className="tutoAdmin">Supprimer des images</h2>
      </div>
      <p>
        Si vous supprimez l&apos;image principale, pensez à en ajouter une
        au-dessus !
      </p>
      <div className="uploadPreview ">
        {values.media.map((medium, index) => (
          <div key={medium.id} className="buttonCategory">
            <img src={`${API_URL}/images/${medium.name}`} alt={medium.alt} />
            <button
              type="button"
              className="deleteCategory"
              onClick={() => handleDelete(index)}
            >
              <img src={deleteIcon} alt="supprimer la catégorie" />
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

DeleteImagesSection.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    texts: PropTypes.arrayOf(PropTypes.object),
    media: PropTypes.arrayOf(PropTypes.object),
    animations: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
