/* eslint-disable func-names */
/* eslint-disable prettier/prettier */
import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import loading from "../../../public/images/loading-buffering.gif";
import DontForget from "../DontForget";

const API_URL = process.env.REACT_APP_API_URL;

function UploadFileVideos({ setselectedFile, selectedFile, setFilename }) {
  let messagePending = document.getElementById("pending");
  let loadingGif = document.getElementById("loadingGif");
  const HandleChangeVideos = (event) => {
    setselectedFile(event.target.files[0]);
  };
  function pending() {
    messagePending.innerHTML = "Veuillez patientez la vidéo est en cours d'envoi sur le serveur. Si le temps est trop long ou que cela ne fonctionne pas, c'est que votre vidéo est trop grosse";
    loadingGif.className = "d-block mt-2 mx-auto";
  }
  const HandleClickVideos = () => {
    if (selectedFile.type !== "video/mp4") {
      alert("only mp4 allowed");
    } else {
      pending();
      const data = new FormData();
      data.append("file", selectedFile);
      axios
        .post(`${API_URL}/api/uploadVideos`, data)
        .then((res) => res.data)
        .then((res) => {
          setFilename(res.filename);
          messagePending.innerHTML = "Vidéo envoyée. N'oubliez pas de cliquer sur le bouton 'Créer/Modifier'";
          loadingGif.className = "d-none";
          alert(`La vidéo a bien été envoyée sur le serveur`);
        })
        .catch((err) => {
          alert(err);
        });
    }
  };
  return (
    <div>
      <div className="AddAdminUpload">
        <input
          type="file"
          name="file"
          accept="video/*"
          className="ChoisirUnFichier"
          onChange={HandleChangeVideos}
        />
        <button type="button" onClick={HandleClickVideos} className="UploadBnt">
          upload
        </button>
      </div>
      <div id="pending" className="text-danger mt-4"></div>
      <img width="60" id="loadingGif" src={loading} className="d-none" alt="chargement vidéo" />
    </div>
  );
}

UploadFileVideos.propTypes = {
  setselectedFile: PropTypes.func.isRequired,
  setFilename: PropTypes.func.isRequired,
  selectedFile: PropTypes.instanceOf(File),
};

export default UploadFileVideos;
