import React from "react";
import { Helmet } from "react-helmet";
import Menu from "../../user/Menu";
import Footer from "../../user/Footer";
import SousMenuActivités from "./SousMenuActivités";
import AffichagePart from "../../admin/partenaires/AffichagePart/AffichagePartenaires";
import "./SousMenuActivités.css";

function PageSousMenuActivités() {
  return (
    <div className="accueil">
      <Helmet>
        <title>Activités - Planète Sciences Sarthe</title>
      </Helmet>
      <Menu />
      <SousMenuActivités />
      <div className="NosPartenairesD">
        <h2 className="NosPartenaires">Nos partenaires</h2>
      </div>
      <AffichagePart />
      <Footer />
    </div>
  );
}

export default PageSousMenuActivités;
