import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import TrouNoir from "../../../../public/images/accueil/Trou_Noir.png";
import "./Event2.css";

const API_URL = process.env.REACT_APP_API_URL;

function sousMenuGalerie() {
  const [events, setEvents] = useState([]);
  const url = `${API_URL}/api/articles?type=événement`;
  useEffect(() => {
    axios
      .get(url)
      .then((result) => result.data)
      .then((data) => {
        setEvents(data);
      });
  }, []);

  return (
    <div>
      <div className="AfficherAllEvent offset-sm-2 offset-md-1 offset-xl-0">
        <div className="TrouNoir">
          <p className="TrouNoirTexte d-none d-xl-block">Autres évenements</p>
          <img src={TrouNoir} alt="trouNoir" className="TrouNoirImg" />
        </div>
        <NavLink className="retourListEvents" to="/évenements">&larr;&nbsp;Retour</NavLink>
        <div className="AllEvents">
          {events.splice(2).map((event) => {
            let path = "/article/" + event.id;
            return (
              <NavLink activeClassName="active" to={path} className="AllEventsL">
                <div className="cardEvent">
                  <img
                    src={`${API_URL}/images/${event.media.filter(
                      (medium) => medium.type === "backgroundImage"
                    )[0].name
                      }`}
                    alt="imageEvent"
                    className="imageEvent2"
                  />
                  <div className="titleEvent2">
                    <p className="titreEvent2">{event.title}</p>
                  </div>
                </div>
              </NavLink>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default sousMenuGalerie;
