import React from "react";
import { NavLink } from "react-router-dom";
import Dons from "../../components/Shared/Dons";

function NavbarDesktop() {
  return (
    <div className="buttonagencement">
      {/* <h1 className="col-5 text-white refNav text-center d-none d-lg-block">Planète Sciences Sarthe</h1> */}

      <label htmlFor="rejoindre" className="label mr-5 mx-xl-auto">
        <NavLink className="text-white d-none d-xl-flex justify-content-center align-items-center button nousContac col-8 mr-5" to="/"><h1 className="refNav">Planète Sciences Sarthe</h1></NavLink>
        <NavLink className="d-flex justify-content-center align-items-center text-white nousContacter button nousContac col-xl-8" to="/form">Nous Contacter</NavLink>
      </label>
      <Dons />
    </div>
  );
}

export default NavbarDesktop;
