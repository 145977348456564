import React, { useState, useEffect } from "react";
import axios from "axios";
import deleteIcon from "../../../public/images/delete.svg";
import "./pageCategories.css";
import ModifyCategoriesInfo from "./ModifyCategoriesInfo";
import getAuthorizationHeaders from "../../services/getAuthorizationHeaders";

const API_URL = process.env.REACT_APP_API_URL;

const ListOfCategories = () => {
  const [categories, setCategories] = useState([]);
  const [chosenId, setChosenId] = useState(null);
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    const url = `${API_URL}/api/categories`;
    axios
      .get(url)
      .then((res) => setCategories(res.data))
      .catch((err) => console.error(err));
  }, []);
  const deleteChosenCategory = (e, index) => {
    const confirmation = confirm(
      "Êtes-vous sûr de vouloir supprimer cette catégorie ?"
    );

    if (confirmation) {
      const headers = getAuthorizationHeaders();
      // on récupère l'id de la catégorie grâce à son index
      const removedCategoryId = parseInt(categories[index].id, 10);
      // on supprime la catégorie de la base de données et de la page
      const updatedCategoriesArray = categories.filter(
        (cat) => cat.id !== removedCategoryId
      );
      function setCat(updCat) {
        setCategories(updCat);
        alert("La catégorie a bien été supprimée");
        window.location.reload();
      }
      axios
        .delete(`${API_URL}/api/categories/${removedCategoryId}`, headers)
        .then(() => setCat(updatedCategoriesArray))
        .catch((err) => console.error(err));
    }
  };

  const showModifyForm = (id) => {
    setChosenId(id);
    setIsShown(true);
  };

  return (
    <>
      <h2>Modifier ou supprimer les catégories existantes :</h2>
      <div className="listOfCategories">
        {categories.map((category, index) => (
          <span
            className="buttonCategory"
            style={{ backgroundColor: `#${category.color}` }}
            key={category.id}
            id={category.id}
            onClick={() => showModifyForm(category.id)}
          >
            {category.name.toUpperCase()}
            <button
              type="button"
              className="deleteCategory"
              onClick={(e) => deleteChosenCategory(e, index)}
            >
              <img src={deleteIcon} alt="supprimer la catégorie" />
            </button>
          </span>
        ))}{" "}
      </div>
      {isShown ? <ModifyCategoriesInfo id={chosenId} /> : ""}
    </>
  );
};

export default ListOfCategories;
