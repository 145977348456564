import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./SousMenuEvenement.css";
import "../evenementsCustom.css";
import axios from "axios";

import mercure from "../../../public/images/accueil/mercury.png";
import phobos from "../../../public/images/asteroïd.png";
import asteroid from "../../../public/images/accueil/déimos.png";
import satellite from "../../../public/images/satellite.png";
import trouNoir from "../../../public/images/accueil/Trou_Noir.png";

const API_URL = process.env.REACT_APP_API_URL;

function sousMenuGalerie() {
  const [events, setEvents] = useState([
    { id: 0, title: "" },
    { id: 0, title: "" },
  ]);
  const url = `${API_URL}/api/articles?type=événement`;
  useEffect(() => {
    axios
      .get(url)
      .then((result) => result.data)
      .then((data) => {
        setEvents(data);
      });
  }, []);

  return (
    <div className="sousMenuE">
      <NavLink activeClassName="active" to="/trophées" className="robotiqueL">
        <div className="robotique">
          <img src={phobos} alt="photos" className="imageRobotique" />
          <div className="texteRobotique asteroRobCustom">
            <p className="robotiqueTXT">Trophées</p>
          </div>
        </div>
      </NavLink>
      <div className="MercureLogoL">
        <img src={mercure} alt="MercureLogo" className="MercureLogo mercureLogCustom" />
        <div className="texteLogoM texteLogCustom">
          <p>EVENEMENTS</p>
        </div>
      </div>
      <NavLink
        activeClassName="active"
        to={`/article/${events[0].id}`}
        className="SemaineTM_L"
      >
        <div className="SemaineTM">
          <img src={satellite} alt="SemaineTM" className="imageSemaineTM" />
          <div className="texteSemaineTM texteSemaineTCustom">
            <p className="SemaineTMTXT">{events[0].title}</p>
          </div>
        </div>
      </NavLink>
      <NavLink
        activeClassName="active"
        to={`/article/${events[1].id}`}
        className="ConcertOphoniusL"
      >
        <div className="ConcertOphonius">
          <img
            src={asteroid}
            alt="ConcertOphonius"
            className="imageConcertOphonius"
          />
          <div className="texteConcertOphonius texteConcOphoCustom">
            <p className="ConcertOphoniusTXT">{events[1].title}</p>
          </div>
        </div>
      </NavLink>
      {events.length > 2 ? (
        <NavLink
          activeClassName="active"
          to="/événements2"
          className="TousNosEvenementsL"
        >
          <div className="TousNosEvenements">
            <img
              src={trouNoir}
              alt="TousNosEvenements"
              className="imageTousNosEvenements"
            />
            <div className="texteTousNosEvenements evenemCustom">
              <p className="TousNosEvenementsTXT ">Autres évenements</p>
            </div>
          </div>
        </NavLink>
      ) : (
        ""
      )}
    </div>
  );
}

export default sousMenuGalerie;
