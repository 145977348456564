/* eslint-disable prettier/prettier */
import React from "react";
import { NavLink } from "react-router-dom";
import "./SystèmePlanete.css";
import earth from "../../../../public/images/accueil/earth.png";
import mars from "../../../../public/images/accueil/marsdetoure.png";
import neptune from "../../../../public/images/accueil/neptunedetoure.png";
import mercury from "../../../../public/images/accueil/mercurydetoure.png";
import logo from "../../../../public/images/planete-sciences.png";

function SystèmePlanete() {
  return (
    <div className="planetes">
      <NavLink activeClassName="active" to="/activités" className="terreL">
        <div className="terre">
          <img src={earth} alt="earth" className="imageTerre opacitePlanetes" />
          <div className="texteTerre">
            <p className="earthTXT planetesTexte">NOS ACTIVITES PROPOSEES</p>
          </div>
        </div>
      </NavLink>
      <div className="fondLogoAcc">
        <img src={logo} alt="earth" className="logoAcc" />
      </div>
      <NavLink activeClassName="active" to="/galerie" className="marsL">
        <div className="mars mb-5">
          <img src={mars} alt="mars" className="imageMars opacitePlanetes" />
          <div className="texteMars">
            <p className="marsTXT planetesTexte planeteGalerie">GALERIE</p>
          </div>
        </div>
      </NavLink>
      <NavLink
        activeClassName="active"
        to="/qui-sommes-nous"
        className="neptuneL"
      >
        <div className="neptune">
          <img
            src={neptune}
            alt="neptune"
            className="imageNeptune opacitePlanetes"
          />
          <div className="texteNeptune">
            <p className="neptuneTXT planetesTexte planeteQui">
              QUI SOMMES-NOUS ?
            </p>
          </div>
        </div>
      </NavLink>
      <NavLink activeClassName="active" to="/évenements" className="planeteL">
        <div className="planete">
          <img
            src={mercury}
            alt="planete"
            className="imagePlanete opacitePlanetes"
          />
          <div className="textePlanete">
            <p className="planeteTXT planetesTexte planeteEvenements">
              EVENEMENTS
            </p>
          </div>
        </div>
      </NavLink>      
    </div>
  );
}

export default SystèmePlanete;
