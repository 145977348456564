/* eslint-disable prettier/prettier */
import axios from "axios";
import React, { useEffect, useState } from "react";
import UploadFileVideos from "./UploadFileVideos";
import "./TropheeAdmin.css";
import DontForget from "../DontForget";

const API_URL = process.env.REACT_APP_API_URL;

function TropheesAdmin() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [selectedFile, setselectedFile] = useState();
  const [filename, setFilename] = useState("1612860892818-robotique2019.mp4");

  // console.log(selectedFile.name);

  const [trophees, setTrophees] = useState([]);
  const [modify, setModify] = useState(false);
  const [itemModify, setItemModify] = useState("");
  const [tropheeModify, setTropheeModify] = useState({
    name: "",
    year: "",
    category: "",
    winner: "",
    texts: [
      { content: "", type: "paragraphe" },
      { content: "", type: "paragraphe" },
      { content: "", type: "paragraphe" },
    ],
    media: [
      {
        name: filename,
        type: "video",
      },
    ],
  });

  const [tropheeAdd, setTropheeAdd] = useState({
    name: "",
    year: "",
    winner: "",
    category: "",
    texts: [
      { content: "", type: "paragraphe" },
      { content: "", type: "paragraphe" },
      { content: "", type: "paragraphe" },
    ],
    media: [
      {
        name: filename,
        type: "video",
      },
    ],
  });

  useEffect(() => {
    axios.get(`${API_URL}/api/trophees`).then((res) => {
      setTrophees(res.data);
    });
  }, []);

  const handleModify = (id) => {
    axios.get(`${API_URL}/api/trophees/${id}`).then((res) => {
      setTropheeModify(res.data);
    });
    if (modify != true) {
      setModify(!modify);
    }
    setItemModify(id);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTropheeModify({ ...tropheeModify, [name]: value });
  };

  const handleChangeText = (event) => {
    const { name, value } = event.target;
    const index = parseInt(name, 10);
    const newTxt = tropheeModify.texts;
    newTxt[index].content = value;
    setTropheeModify({ ...tropheeModify, texts: newTxt });
  };

  const handleAddText = (event) => {
    const { name, value } = event.target;
    const index = parseInt(name, 10);
    const newTxt = tropheeModify.texts;
    newTxt[index].content = value;
    setTropheeAdd({ ...tropheeModify, texts: newTxt });
  };

  const handleAddChange = (event) => {
    const { name, value } = event.target;
    setTropheeAdd({ ...tropheeAdd, [name]: value });
  };

  const handleSubmitModify = () => {
    // id à modifier stocker dans itemModify
    // lié au put
    const trophee = tropheeModify;
    trophee.media = {
      name: filename,
      alt: filename,
      type: "video",
    };
    axios
      .put(`${API_URL}/api/trophees/${itemModify}`, trophee)
      .then(() => {
        alert("Modification effectuée");
        window.location.reload();
      });
    // window.alert("En construction");
  };

  const handleSubmitAdd = () => {
    // lié au post
    const trophee2 = tropheeAdd;
    trophee2.media = {
      name: filename,
      alt: filename,
      type: "video",
    };
    axios
      .post(`${API_URL}/api/trophees/`, trophee2)
      .then(window.alert("Le trophée a bien été créé"))
      .catch((err) => {
        window.alert(err);
      });
    window.location.reload();
  };

  const handleDelete = (id) => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce trophée ?"
    );

    if (confirmation) {
      axios
        .delete(`${API_URL}/api/trophees/${id}`)
        .then(window.alert("Le trophée a bien été supprimé"))
        .catch((err) => {
          window.alert(err);
        });
      window.location.reload();
    }
  };

  function creerTrophee() {
    window.location.reload();
  }

  return (
    <div className="globalContainer">
      <h1 className="mt-4">Trophées existants :</h1>
      {modify ? (
        <button className="btn btn-secondary mt-4 mb-3" onClick={creerTrophee}>Ajouter un nouveau trophée</button>
      ) : null}
      <div className="tropheesList">
        {trophees.map((trophee) => (
          <div key={trophee.id} className="tropheeContainer mb-3">
            <p>{trophee.name}</p>
            <p>{trophee.year}</p>
            <p>{trophee.category}</p>
            <button
              type="button"
              onClick={() => {
                handleModify(trophee.id);
              }}
            >
              modifier
            </button>
            <button
              type="button"
              onClick={() => {
                handleDelete(trophee.id);
              }}
            >
              supprimer
            </button>
          </div>
        ))}
      </div>
      {modify ? (
        <div className="modifySection">
          <h2>Modification du trophée</h2>
          <div className="modifySectionTxt">
            <div className="modifySectionTxtRight">
              <label className="inputElementTxt">
                Présentation du trophée :
                <textarea
                  name="0"
                  value={tropheeModify.texts[0].content}
                  onChange={handleChangeText}
                />
              </label>
              <label className="inputElementTxt">
                Objectifs & règles
                <textarea
                  name="1"
                  value={tropheeModify.texts[1].content}
                  onChange={handleChangeText}
                />
              </label>
              <label className="inputElementTxt">
                Horaires et lieux :
                <textarea
                  name="2"
                  value={tropheeModify.texts[2].content}
                  onChange={handleChangeText}
                />
              </label>
            </div>
            <div className="modifySectionTxtLeft">
              <label className="inputElement">
                Titre :
                <input
                  name="name"
                  value={tropheeModify.name}
                  onChange={handleChange}
                />
              </label>
              <label className="inputElement">
                Année :
                <input
                  name="year"
                  value={tropheeModify.year}
                  onChange={handleChange}
                />
              </label>
              <label className="inputElement">
                Catégorie :
                <input
                  name="category"
                  value={tropheeModify.category}
                  onChange={handleChange}
                />
              </label>
              <label className="inputElement">
                Gagnant :
                <input
                  name="winner"
                  value={tropheeModify.winner}
                  onChange={handleChange}
                />
              </label>
              <label className="inputElement">
                Vidéo :
              </label>
              <UploadFileVideos
                setselectedFile={setselectedFile}
                setFilename={setFilename}
                selectedFile={selectedFile}
              />
              <div className="mt-5">
                <DontForget />
              </div>
              <button
                type="button"
                className="submit-button"
                onClick={handleSubmitModify}
              >
                Modifier
              </button>
            </div>

          </div>
        </div>
      ) :

        <div className="modifySection" id="AddSection">

          <h2>Ajouter un nouveau trophée</h2>
          <div className="modifySectionTxt  marginForm">
            <div className="modifySectionTxtRight">
              <label className="inputElementTxt">
                Présentation du trophée :
                <textarea
                  name="0"
                  value={tropheeAdd.texts[0].content}
                  onChange={handleAddText}
                />
              </label>
              <label className="inputElementTxt">
                Objectifs & règles :{" "}
                <textarea
                  name="1"
                  value={tropheeAdd.texts[1].content}
                  onChange={handleAddText}
                />
              </label>
              <label className="inputElementTxt">
                Horaires et lieux :
                <textarea
                  name="2"
                  value={tropheeAdd.texts[2].content}
                  onChange={handleAddText}
                />
              </label>
            </div>
            <div className="modifySectionTxtLeft">
              <label className="inputElement">
                Titre :
                <input
                  name="name"
                  value={tropheeAdd.name}
                  onChange={handleAddChange}
                />
              </label>
              <label className="inputElement">
                Année :
                <input
                  name="year"
                  type="number"
                  value={tropheeAdd.year}
                  onChange={handleAddChange}
                />
              </label>
              <label className="inputElement">
                Catégorie :
                <input
                  name="category"
                  value={tropheeAdd.category}
                  onChange={handleAddChange}
                />
              </label>
              <label className="inputElement">
                Gagnant :
                <input
                  name="winner"
                  value={tropheeAdd.winner}
                  onChange={handleAddChange}
                />
              </label>
              <label className="inputElement">
                Vidéo (.mp4) :
              </label>
              <UploadFileVideos
                setselectedFile={setselectedFile}
                setFilename={setFilename}
                selectedFile={selectedFile}
              />
              <div className="mt-5">
                <DontForget />
              </div>
              <button
                type="button"
                onClick={handleSubmitAdd}
                className="submit-button tropheeBtn"
              >
                Créer
              </button>
            </div>

          </div>
        </div>
      }
    </div>
  );
}

export default TropheesAdmin;
