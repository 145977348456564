import React from "react";
import { Helmet } from "react-helmet";
import Menu from "../../../user/Menu";
import Footer from "../../../user/Footer";
import Event2 from "./Event2";
import "./Event2.css";
import AffichagePartenaires from "../../../admin/partenaires/AffichagePart/AffichagePartenaires";

function PageEvent2() {
  return (
    <div className="accueil">
      <Helmet>
        <title>Évènements - Planète Sciences Sarthe</title>
      </Helmet>
      <Menu />
      <Event2 />
      <AffichagePartenaires />
      <Footer />
    </div>
  );
}

export default PageEvent2;
