import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./AdminAPP.css";
import axios from "axios";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import Login from "./login/Login";
import BvnBar from "./BvnBar";
import NavBar from "./NavBar";
import Partenaires from "./partenaires/AdminComp";
import Accueil from "./acceuil/AdminAcceuil";
import Event from "./évenement/AdminEvent";
import Animation from "./animation/AdminAnimation";
import Activités from "./activités/AdminActivités";
import Nous from "./nous/AdminNous";
import AddAnimationForm from "./animation/AddAnimationForm";
import TropheesAdmin from "./trophées/TropheesAdmin";
import GallerieAdmin from "./galerie/GallerieAdmin";
import PageCategories from "./categories/PageCategories";
import AddEventForm from "./évenement/AddEventForm";
import ModifyActivitésForm from "./activités/ModifyActivitésForm";
import ModifyAnimationForm from "./animation/ModifyAnimationForm";
import getAuthorizationHeaders from "../services/getAuthorizationHeaders";

const API_URL = process.env.REACT_APP_API_URL;

// const API_URL = "http://localhost:8000";

function AdminApp() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [login, setLogin] = useState(false);

  const removeTokenAndLogout = () => {
    localStorage.removeItem("TOKEN");
    setLogin(false);
    history.push("/admin");
  };

  const checkIfToken = () => {
    const token = localStorage.getItem("TOKEN");
    if (token) {
      axios
        .post(
          `${API_URL}/api/administrateurs/verify-token`,
          undefined,
          getAuthorizationHeaders()
        )
        .then(() => {
          setLogin(true);
        })
        .catch(() => {
          removeTokenAndLogout();
        });
    }
  };

  useEffect(() => {
    checkIfToken();
  }, []);

  return (
    <>
      {!login && (
        <>
          <Helmet>
            <title>Connexion - Planète Sciences Sarthe</title>
          </Helmet>
          <Login setLogin={setLogin} />
        </>
      )}
      {login && (
        <div className="totuAdmin">
          <Helmet>
            <title>Administration - Planète Sciences Sarthe</title>
          </Helmet>
          <NavBar />
          <div className="adminApp">
            <BvnBar />
            <Switch>
              <Route exact path={`${path}/`}>
                <Nous removeTokenAndLogout={removeTokenAndLogout} />
              </Route>
              <Route path={`${path}/partenaires`}>
                <Partenaires removeTokenAndLogout={removeTokenAndLogout} />
              </Route>
              <Route path={`${path}/accueil`} component={Accueil} />
              <Route path={`${path}/événement`} component={Event} />
              <Route path={`${path}/animations`} component={Animation} />
              <Route
                path={`${path}/add-animations`}
                component={AddAnimationForm}
              />
              <Route
                path={`${path}/modify-animations/:id`}
                component={ModifyAnimationForm}
              />
              <Route path={`${path}/activités`} component={Activités} />
              <Route path={`${path}/qui-sommes-nous`}>
                <Nous removeTokenAndLogout={removeTokenAndLogout} />
              </Route>
              <Route
                path={`${path}/trophees-admin`}
                component={TropheesAdmin}
              />
              <Route path={`${path}/gallerie-admin`}>
                <GallerieAdmin removeTokenAndLogout={removeTokenAndLogout} />
              </Route>
              <Route path={`${path}/categories`} component={PageCategories} />
              <Route path={`${path}/add-events`} component={AddEventForm} />
              <Route
                path={`${path}/modify-activities/:id`}
                component={ModifyActivitésForm}
              />
            </Switch>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminApp;
