import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./QuiSommesNous.css";
import "./quiSommesNousUnique.css";
import fBas from "../../public/images/FuséeDuBasR.png";
import fMid from "../../public/images/FuséeDuMilieuR.png";
import fHaut from "../../public/images/FuséeDuHautR.png";
import Menu from "../user/Menu";

const API_URL = process.env.REACT_APP_API_URL;

function QuiSommesNous() {
  const [text, setText] = useState([]);
  const [displayOne, setDisplayOne] = useState(false);
  const [displayTwo, setDisplayTwo] = useState(false);

  useEffect(() => {
    axios.get(`${API_URL}/api/nous/?`).then((res) => {
      setText(res.data);
    });
  }, []);

  const takeOff = (element) => {
    const e = document.querySelector(element);
    const move = () => {
      e.style.transform = "translateY(-5px)";
    };
    const moveTop = setInterval(move, 20);
    setTimeout(() => clearInterval(moveTop), 2000);
  };

  const text1 = text.paragraphe1;
  const text2 = text.paragraphe2;
  const text3 = text.paragraphe3;

  return (
    <div>
      <Helmet>
        <title>Qui sommes-nous ? - Planète Sciences Sarthe</title>
      </Helmet>
      <Menu />
      <div className="fusee-globalContainer customFusContainer">
        <div className="fusee-leftSide customNoBlank">
          <div className="fusee-fuseeBloc customFusBloc">
            <img
              className="fusee-imgFusee fuseeHaut customTete"
              alt="tete de fusee"
              id="fHaut"
              src={fHaut}
              onClick={() => takeOff(".fuseeBloc")}
            />
            <img
              className="fusee-imgFusee fuseeMilieu customMilieu"
              id="fMid"
              alt="milieu de fusee"
              src={fMid}
              onClick={() => {
                if (displayTwo === false) {
                  const fHautB = document.getElementById("fHaut");
                  fHautB.style.display = "flex";
                  const text2B = document.getElementById("text2");
                  text2B.style.display = "none";
                  const text3B = document.getElementById("text3");
                  text3B.style.display = "flex";
                  setDisplayTwo(true);
                }
              }}
            />
            <img
              className="fusee-imgFusee customBas"
              id="fBas"
              alt="bas de la fusée"
              src={fBas}
              onClick={() => {
                if (displayOne === false) {
                  const fMidB = document.getElementById("fMid");
                  fMidB.style.display = "flex";
                  const text1B = document.getElementById("text1");
                  text1B.style.display = "none";
                  const text2B = document.getElementById("text2");
                  text2B.style.display = "flex";
                  setDisplayOne(true);
                }
              }}
            />
          </div>
        </div>
        <div className="fusee-rightSide textMaxSize customFusRightSite">
          <p className="fusee-textPres" id="text1">
            {text1}
          </p>
          <p className="fusee-textPres" id="text2">
            {text2}
          </p>
          <p className="fusee-textPres" id="text3">
            {text3}
          </p>
          <h2 className="fusee-fusee-h2">
            Clique sur la navette pour lire la suite ...
          </h2>
        </div>
      </div>
    </div>
  );
}

export default QuiSommesNous;
