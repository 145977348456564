import React from "react";

function AdminAcceuil() {
  return (
    <div className="AdminComp">
      <p>acceuil</p>
    </div>
  );
}

export default AdminAcceuil;
