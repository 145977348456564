import React, { useState } from "react";
import PropTypes from "prop-types";
import UploadFile from "../partenaires/uploadFile/UploadFile";

const API_URL = process.env.REACT_APP_API_URL;
// composant utilisé dans le composant principal suivant
const InputAlt = ({ index, values, setValues }) => {
  const handleChangeAlt = (e) => {
    setValues(() => {
      const updatedMediaArray = values.media;
      updatedMediaArray[index].alt = e.target.value;
      return {
        ...values,
        media: updatedMediaArray,
      };
    });
  };
  return (
    <>
      <label htmlFor="alt">Descriptif de cette image :</label>
      <input
        type="text"
        placeholder="descriptif de l'image"
        value={values.media[index].alt}
        onChange={handleChangeAlt}
      />
    </>
  );
};

// composant principal du fichier
const AddImagesSection = ({ setFilename, values, setValues, type }) => {
  const [selectedFile, setselectedFile] = useState("");
  const handleChangeMainAlt = (e) => {
    if (values.media.length > 0) {
      setValues(() => {
        const updatedMediaArray = values.media;
        updatedMediaArray[0].alt = e.target.value;
        return {
          ...values,
          media: updatedMediaArray,
        };
      });
    }
  };
  return (
    <section className="addCategories">
      <div className="addAnimationForm ">
        <h2 className="tutoAdmin">Ajouter des images</h2>
      </div>
      <div className="uploadPreview">
        <div className="uploadFormAnimations">
          <h3>Ajouter l&apos;image principale de l&apos;{type}</h3>
          <UploadFile
            setselectedFile={setselectedFile}
            selectedFile={selectedFile}
            setFilename={setFilename}
          />
          <label htmlFor="alt">
            Ainsi qu&apos;un descriptif de l&apos;image pour les navigateurs :
          </label>
          <input
            type="text"
            placeholder={
              values.media.length > 0
                ? "descriptif de l'image"
                : "uploader d'abord l'image"
            }
            value={values.media[0] ? values.media[0].alt : ""}
            onChange={handleChangeMainAlt}
          />
        </div>
        {values.media.length > 0 && (
          <img
            src={`${API_URL}/images/${values.media[0].media_name}`}
            alt="test"
          />
        )}
      </div>
      {/* <h3>Souhaitez-vous ajouter une vidéo de l&apos;{type} ?</h3>
      <i>WIP: composant ci-dessous à remplacer par l&apos;upload de vidéos</i>
      <UploadFile
        setselectedFile={setselectedFile}
        selectedFile={selectedFile}
        setFilename={setFilename}
      /> */}
      <h3 className="mt-5">Ajouter d&apos;autres photos de l&apos;{type} :</h3>
      <UploadFile
        setselectedFile={setselectedFile}
        selectedFile={selectedFile}
        setFilename={setFilename}
      />
      <div className="uploadPreview">
        {values.media.length > 1
          ? values.media
              .filter((e, index) => index > 0)
              .map((picture, index) => (
                <div className="imagesPreviewRow">
                  <img
                    src={`${API_URL}/images/${picture.media_name}`}
                    alt="test"
                  />
                  <InputAlt
                    index={index + 1}
                    values={values}
                    setValues={setValues}
                  />
                </div>
              ))
          : ""}
      </div>
    </section>
  );
};

AddImagesSection.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.object),
    media: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.number),
    new_categories: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  setFilename: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

InputAlt.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.object),
    media: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.number),
    new_categories: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default AddImagesSection;
