import React from "react";
import ListOfActivities from "./ListOfActivities";
import { NavLink } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

function AdminActivités() {
  return (
    <div className="AdminComp">
      <h2>Modifier une des pages d&apos;activités</h2>
      <ListOfActivities />
      <NavLink className="btn btn-secondary btnRobotFromEvent mt-5" to={`/admin/animations`} >
        <h4 className="mt-1">Animations des différentes activités</h4>
        <img className="imageRobotEvent img-responsive" src={`${API_URL}/Images/spaceman.jpg`} alt="planete science robotique" />
      </NavLink>
    </div>
  );
}

export default AdminActivités;
