import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const ListOfActivities = () => {
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    axios.get(`${API_URL}/api/en_tete`).then((res) => setActivities(res.data));
  }, []);
  return (
    <div>
      {activities.map((activity) => (
        <NavLink
        key={activity.id}
          to={`/admin/modify-activities/${activity.id}`}
          className="addCategories"
        >
          <div key={activity.id}>
            <h2>{activity.title}</h2>
            <img
              src={`${API_URL}/images/${activity.media[0].name}`}
              alt={activity.media[0].alt}
              className="activitiesPreview"
            />
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default ListOfActivities;
