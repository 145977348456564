import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./FormContact.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Menu from "../user/Menu";
import Footer from "../user/Footer";
import AffichagePartenaires from "../admin/partenaires/AffichagePart/AffichagePartenaires";

const API_URL = process.env.REACT_APP_API_URL;

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function formContact() {
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [addressPs, setAddressPs] = useState("");
  const [phonePs, setPhonePs] = useState("");

  const onClickHandle = () => {
    if (
      !email ||
      !lastname ||
      !firstname ||
      !subject ||
      !phone ||
      !message
    ) {
      const messageSend = document.getElementById("form-send");
      messageSend.innerHTML = "Veuillez remplir tous les champs !";
    } else {
      axios
        .post(`${API_URL}/api/email`, {
          nom: lastname,
          prenom: firstname,
          mail: email,
          telephone: phone,
          sujet: subject,
          message,
        },
          {
            headers: {
              "g-recaptcha-response": reCaptchaToken
            }
          })
        .then((res) => res.data)
        .then(() => {
          const messageSend = document.getElementById("form-send");
          messageSend.innerHTML = "Message envoyé !";
        })
        .catch(() => {
          const messageSend = document.getElementById("form-send");
          messageSend.innerHTML = "Une erreur s'est produite !";
        });
    }
  };
  const getCoordinates = () => {
    axios.get(`${API_URL}/api/nous`).then((res) => {
      setAddressPs(res.data.address);
      setPhonePs(res.data.phone);
    });
  };
  useEffect(() => {
    getCoordinates();
  }, []);

  const [reCaptchaToken, setReCaptchaToken] = useState("");

  function onChange(value) {
    setReCaptchaToken(value);
  }

  return (
    <div>
      <Helmet>
        <title>Contact - Planète Sciences Sarthe</title>
      </Helmet>
      <Menu />
      <div className="form">
        <div className="form-img-title" />
        <div className="form-div-title">
          <div className="form-title contact">Nous Contacter</div>
        </div>
        <form className="form-contact">
          <div className="form-input">
            <div className="form-name">
              <label
                className="form-label"
                id="form-lastname"
                htmlFor="lastname"
              >
                Nom :
                <input
                  className="form-field"
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={lastname}
                  onChange={(event) => setLastname(event.target.value)}
                />
              </label>
              <label
                className="form-label"
                id="form-firstname"
                htmlFor="firstname"
              >
                Prénom :
                <input
                  className="form-field"
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={firstname}
                  onChange={(event) => setFirstname(event.target.value)}
                />
              </label>
            </div>
            <label className="form-label" id="form-email" htmlFor="email">
              Mail :
              <input
                className="form-field"
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </label>
            <label className="form-label" id="form-phone" htmlFor="phone">
              Télephone :
              <input
                className="form-field"
                type="number"
                name="phone"
                id="phone"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </label>
            <label className="form-label" id="form-email" htmlFor="subject">
              Sujet :
              <select
                className="form-field pl-3"
                name="subject"
                id="subject"
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
              >
                <option>Sélectionnez un thème</option>
                <option value="Demande de devis">Demande de devis</option>
                <option value="Inscription au club sciences">
                  Inscription au club sciences
                </option>
                <option value="Inscription trophée/challanges robotiques">
                  Inscription trophée/challenge robotique
                </option>
                <option value="Bénévolat">Bénévolat</option>
                <option value="Demande de renseignements">
                  Demande de renseignements
                </option>
                <option value="Autre ...">Autre ...</option>
              </select>
            </label>
          </div>
          <div className="form-textarea">
            <label className="form-label" id="form-message" htmlFor="message">
              Message :
              <textarea
                type="text"
                name="message"
                id="message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </label>
          </div>
        </form>
        <div className="form-submit mt-5">
          <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={onChange} />
          <div className="form-div-button">
            <button
              className="form-button"
              type="button"
              onClick={onClickHandle}
            >
              Envoyer
            </button>
          </div>
          <p id="form-send" />
        </div>
        <h2 className="form-h2">Nos coordonnées</h2>
        <div className="form-coords">
          <p>Adresse:</p> <p>{addressPs}</p>
          <p>Téléphone:</p> <p> {phonePs}</p>
        </div>
      </div>
      <div className="NosPartenairesFC">
        <h2 className="NosPartenairesFFC">Nos partenaires</h2>
      </div>
      <AffichagePartenaires />
      <Footer />
    </div>
  );
}

export default formContact;
