/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { NavLink } from "react-router-dom";
import "./AdminAPP.css";
import logo from "../../public/images/planete-sciences.png";

function NavBar() {
  const menuItem = [
    {
      id: 1,
      title: "Activités",
      path: "/activités",
    },
    {
      id: 2,
      title: "Qui sommes-nous",
      path: "/qui-sommes-nous",
    },
    {
      id: 3,
      title: "Galerie",
      path: "/gallerie-admin",
    },
    // {
    //   id: 4,
    //   title: "Animations",
    //   path: "/animations",
    // },
    {
      id: 4,
      title: "Evénements",
      path: "/événement",
    },
    {
      id: 5,
      title: "Partenaires",
      path: "/partenaires",
    },
    {
      id: 6,
      title: "Catégories (thèmes)",
      path: "/categories",
    },
    // {
    //   id: 7,
    //   title: "Trophées",
    //   path: "/trophees-admin",
    // },
    
  ];

  return (
    <div className="navBar">
      <div className="fondLogo">
        <NavLink to="/">
          <img className="imageLogo" src={logo} alt="logo" />
        </NavLink>
      </div>
      <ul className="navGauche">
        {menuItem.map((item) => {
          let css = "textNav";
          if (item.id === 6) {
            css += " mt-3";
          }
          return (
            <div key={item.id} >
              {item.id === 6 &&
                <hr className="hrNavStyle"></hr>
              }
              <li className={css}>
                <NavLink
                  activeClassName="present"
                  className="box"
                  to={`/admin${item.path}`}
                >
                  {item.title}
                </NavLink>
              </li>
            </div>
          )
        })}
      </ul>
      {/* <h1 className="version">v.1.2</h1> */}
    </div>
  );
}

export default NavBar;
