import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import "../mentions-legales/mentionsLegales.css";

const API_URL = process.env.REACT_APP_API_URL;

function MentionsLegales() {


    return (
        <div className="accueil">
            <Helmet>
                <title>Mentions légales - Planète Sciences Sarthe</title>
            </Helmet>
            <div className="row d-flex justify-content-center mx-auto stickyBackGallery">
                <a className="mt-5 text-white textDecoNone btn mt-4 mb-4 btnRadius" href="/">
                    Retour
                </a>
                <h1 className="mt-3 col-12 text-center text-white">Mentions Légales</h1>
                <div className="mentionsLegalesContainer">
                    <b>Responsable du site</b><br></br>
                    Planète Science Sarthe<br></br>
                    N° Siret : 449 021 930 00036<br></br>
                    Tél : 02.43.93.87.58<br></br>
                    Web : planete-sciences-sarthe.fr<br></br><br></br>

                    <b>Réalisation du site</b><br></br>
                    - Audren Thibault 117 rue Saint Hilaire, 28400 Nogent Le Rotrou, France<br></br>
                    N° Siret : 877 574 491 00016<br></br>
                    Tél : 06 74 05 13 42<br></br>
                    E-mail : audrenthibault@gmail.com<br></br>
                    - Wild Code School - La Loupe<br></br><br></br>

                    <b>Conception graphique</b><br></br>
                    - Audren Thibault 117 rue Saint Hilaire, 28400 Nogent Le Rotrou, France<br></br>
                    N° Siret : 877 574 491 00016<br></br>
                    Tél : 06 74 05 13 42<br></br>
                    E-mail : audrenthibault@gmail.com<br></br>
                    - Wild Code School - La Loupe<br></br><br></br>

                    <b>Hébergement du site</b><br></br>
                    OVH, 2 rue Kellermann, 59100 Roubaix, France<br></br>
                    SAS au capital de 10 000 000 €<br></br>
                    RCS Roubaix - Tourcoing 424 761 419 00045<br></br>
                    Code APE 6202A<br></br>
                    N° TVA : FR 22 424 761 419<br></br>
                    Web : www.ovh.com<br></br><br></br>

                    L'ensemble de ce site relève des législations françaises et internationales sur le droit d'auteur et la propriété intellectuelle.<br></br>
                    Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques et photographiques.<br></br>
                </div>
            </div>
        </div>
    );
}

export default MentionsLegales;
