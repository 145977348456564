import React from "react";
import "./UploadFile.css";
import PropTypes from "prop-types";
import axios from "axios";
// import getAuthorizationHeaders from "../../../services/getAuthorizationHeaders";

const API_URL = process.env.REACT_APP_API_URL;

function UploadFile({ setselectedFile, selectedFile, setFilename }) {
  // const headers = getAuthorizationHeaders();

  const HandleChangePartenaire = (event) => {
    setselectedFile(event.target.files[0]);
  };
  const HandleClickPartenaire = () => {

    if (
      selectedFile.type !== "image/jpeg" &&
      selectedFile.type !== "image/jpg" &&
      selectedFile.type !== "image/png"
    ) {
      alert("only jpeg, jpg and png allowed");
    } else {
      const data = new FormData();
      data.append("file", selectedFile);

      axios
        .post(`${API_URL}/api/upload`, data)
        .then((res) => res.data)
        .then((res) => {
          setFilename(res.filename);
          alert(`L'image ${res.filename} a bien été enregistrée`);
        })
        .catch((err) => {
          alert(err);
        });
    }
  };
  return (
    <div className="AddAdminUpload">
      <input
        type="file"
        name="file"
        accept="image/*"
        className="ChoisirUnFichier"
        onChange={HandleChangePartenaire}
      />
      <button
        type="button"
        onClick={HandleClickPartenaire}
        className="UploadBnt btn btn-light"
      >
        Upload
      </button>
    </div>
  );
}

UploadFile.propTypes = {
  setselectedFile: PropTypes.func.isRequired,
  setFilename: PropTypes.func.isRequired,
  selectedFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default UploadFile;
