/* eslint-disable prefer-template */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import PropTypes from "prop-types";
import "./gallerie.css"
import axios from "axios";
import UploadFileGallerie from "./UploadFileGallerie";
import suppr from "../../../public/images/suppr.png";
import getAuthorizationHeaders from "../../services/getAuthorizationHeaders";
import $ from 'jquery';

const API_URL = process.env.REACT_APP_API_URL;

function GallerieAdmin({ removeTokenAndLogout }) {

    const headers = getAuthorizationHeaders();

    const [images, setImage] = useState([]);
    const url = `${API_URL}/api/gallerie/`;

    function deleteClick(id, type) {
        const validation = confirm(
            "Etes-vous certain de vouloir supprimer ce fichier ?"
        );
        if (validation) {
            axios
                .delete(`${url}/${id}`, headers)
                .catch((err) => {
                    if (err.response.status !== 401) {
                        alert(err.response.data.errorMessage);
                    } else {
                        alert("Vous devez vous reconnecter");
                        removeTokenAndLogout();
                    }
                });
            // window.location.reload();
            if (type.startsWith("video")) {
                setTimeout(function (){
                    $("#videoBtn").click()
                }, 1000);
            } else if (type.startsWith("image")) {
                setTimeout(function (){
                    $("#imageBtn").click()
                }, 1000);
            } else if (type.startsWith("pdf")) {
                setTimeout(function (){
                    $("#pdfBtn").click()
                }, 1000);
            }
        }
    }

    function image(isImage) {
        axios
            .get(url + "/imagevid/" + isImage)
            //   .then(window.alert("dans get images"))
            .catch((err) => {
                // window.alert(err);
            })
            .then((res) => {
                if (res != undefined) {                    
                    setImage(res.data)
                } else {
                    alert("Il n'y a rien à afficher");
                    window.location.reload();
                }
            });
    }

    return (
        <div>

            <div className="container-fluid">
                <h3 className="text-center font-weight-bold mt-5">
                    Voir les images ou les vidéos :
                </h3>
                <div className="row d-flex justify-content-center">
                    <div className="mt-5 mb-5 mr-4">
                        <button type="button" className="mr-5 btn btn-secondary" id="pdfBtn" onClick={() => image(2)}>Voir les articles de presse</button>
                        <button type="button" className="mr-5 btn btn-secondary" id="imageBtn" onClick={() => image(1)}>Voir les images</button>
                        <button type="button" className="btn btn-secondary" id="videoBtn" onClick={() => image(0)}>Voir les vidéos</button>
                    </div>
                </div>
                <div className="row mb-5 ml-5">
                    {images.map((image) => {
                        let col = "";
                        let pdfName = "";
                        let delCol = "";
                        // console.log(image.type);
                        if (image.type == "image") {
                            col = "col-3";
                        } else if (image.type == "video") {
                            col = "col-6";
                        } else if (image.type == "pdf") {
                            col = "col-3"
                            pdfName = image.alt;
                            delCol = "ml-5";
                        }
                        return (
                            <div className={col}>
                                {image.type == "image" &&
                                    <img width="200" src={API_URL + "/Images/" + image.name} alt="gallerie-admin" className="mb-5" />
                                }
                                {image.type == "video" &&
                                    <div className="d-inline-block">
                                        <video controls width="500" height="300">
                                            <source src={API_URL + "/videos/" + image.name}></source>
                                            Désolé votre navigateur ne supporte pas les vidéos intégrées
                                        </video>
                                    </div>
                                }
                                {image.type == "pdf" &&
                                    <div>
                                        <div className="pdfStyle">{pdfName}</div>
                                        <a className="pdfLink" href={API_URL + "/Images/" + image.name} target="_blank">Voir le pdf</a>
                                    </div>
                                }
                                <div className="trashAlign DivIcon d-inline-block">
                                    <img className="LogoIcon" alt="logoSuppr" src={suppr} onClick={() => deleteClick(image.id, image.type)} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <h3 className="text-center font-weight-bold mb-5 mt-3">
                Ajouter une nouvelle image, vidéo ou document pdf :
            </h3>
            <div className="mb-5">
                <UploadFileGallerie />
            </div>
        </div>
    )
}

GallerieAdmin.propTypes = {
    removeTokenAndLogout: PropTypes.func.isRequired,
};

export default GallerieAdmin;
