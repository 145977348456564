import React from "react";
import { NavLink } from "react-router-dom";
import "./SousMenuGalerie.css";
import mars from "../../../public/images/accueil/marsdetoure.png";
import phobos from "../../../public/images/accueil/Phobos.png";
import déimos from "../../../public/images/accueil/déimos.png";
import satellite from "../../../public/images/satellite.png";

function sousMenuGalerie() {
  return (
    <div className="sousMenu">
      <NavLink activeClassName="active" to="/galerie-photo" className="photosL asteroPhotosGalerie">
        <div className="photos">
          <img src={phobos} alt="photos" className="imagePhotos" />
          <div className="textePhotos">
            <p className="photosTXT">Photos</p>
          </div>
        </div>
      </NavLink>
      <div className="MoonLogoL marsGaler">
        <img src={mars} alt="MoonLogo" className="MoonLogo" />
        <div className="texteLogoG">
          <p>GALERIE</p>
        </div>
      </div>
      <NavLink activeClassName="active" to="/galerie-video" className="vidéosL">
        <div className="vidéos">
          <img src={satellite} alt="vidéos" className="imageVidéos" />
          <div className="texteVidéos">
            <p className="vidéosTXT">Vidéos</p>
          </div>
        </div>
      </NavLink>
      <NavLink
        activeClassName="active"
        to="/galerie-article"
        className="ArticlesDePressesL"
      >
        <div className="ArticlesDePresses">
          <img
            src={déimos}
            alt="ArticlesDePresses"
            className="imageArticlesDePresses"
          />
          <div className="texteArticlesDePresses">
            <p className="ArticlesDePressesTXT">Articles de presse</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default sousMenuGalerie;
