import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";

ReactDOM.render(
  <BrowserRouter>
    <Helmet>
      <title>Planète Sciences Sarthe</title>
    </Helmet>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
