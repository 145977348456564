import { PropTypes } from "prop-types";
import React from "react";
import plus from "../../../public/images/plus.png";

function AddPartenaire({ AddNvPart }) {
  return (
    <div className="AddPartenaire" onClick={AddNvPart}>
      <img src={plus} alt="imageAdd" className="imageAdd" />
      <h1 className="AddNvPart">ajouter un nouveau partenaire</h1>
    </div>
  );
}

AddPartenaire.propTypes = {
  AddNvPart: PropTypes.func.isRequired,
};

export default AddPartenaire;
