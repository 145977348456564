import React from "react";
import PropTypes from "prop-types";

const API_URL = process.env.REACT_APP_API_URL;

export const Image = ({ medias }) => {
    const [image, setImage] = React.useState(null);

    if (medias.length === 0) {
        return null;
    }

    if (image === null) {
        const test = medias.filter((img) => img.type).filter((img) => img.type === "backgroundImage")

        if (test.length) {
            setImage(test[0].name);
        }
    }

    return (
        <img
          src={`${API_URL}/images/${image}`}
          alt="pas de fond"
        />
    );
};

Image.defaultProps = {
    medias: [],
};

Image.propTypes = {
    medias: PropTypes.arrayOf(PropTypes.object),
};
