import React from "react";
import "./Menu.css";
import { NavLink } from "react-router-dom";
import logo from "../../public/images/planete-sciences.png";

import NavbarDesktop from "./Navbar/NavbarDesktop";
import NavbarMobile from "./Navbar/NavbarMobile";

function Menu() {
  return (
    <div className="menu">
      <div className="NvDesk">
        <div className="fondLogoNb">
          <NavLink to="/">
            <img src={logo} alt="logo" className="logo" />
          </NavLink>
        </div>
        <div className="divbutton">
          <NavbarDesktop />
        </div>
      </div>
      <div className="NvMob">
        <NavLink to="/">
          <img src={logo} alt="logo" className="logo" />
        </NavLink>
        <NavbarMobile />
      </div>
    </div>
  );
}

export default Menu;
