import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";

const API_URL = process.env.REACT_APP_API_URL;

const ModifyCategoriesInfo = ({ id }) => {
  const [updatedCategory, setUpdatedCategory] = useState({
    name: "",
    color: "",
  });
  const [feedbackMessage, setFeedbackMessage] = useState("");
  useEffect(() => {
    axios.get(`${API_URL}/api/categories/${id}`).then((res) => {
      setUpdatedCategory(res.data[0]);
    });
  }, []);
  const handleChangeNewCategory = (e) => {
    const { name, value } = e.target;
    setUpdatedCategory({ ...updatedCategory, [name]: value });
  };
  const handleSubmitCategory = (e) => {
    e.preventDefault();
    if (updatedCategory.name !== "" && updatedCategory.color !== "") {
      const url = `${API_URL}/api/categories/${id}`;
      axios
        .put(url, updatedCategory)
        .then(setFeedbackMessage("La catégorie a bien été modifiée"))
        .catch((err) => {
          console.error(err);
          setFeedbackMessage(
            "un problème avec le serveur est survenu. Veuillez contacter l'équipe de développement"
          );
        });
    } else {
      setFeedbackMessage("Veuillez remplir tous les champs");
    }
  };
  return (
    <div className="AdminComp d-none">
      <h2>Modifier la catégorie :</h2>
      <form className="addNewCategoryForm" onSubmit={handleSubmitCategory}>
        <div className="formRow">
          <label htmlFor="firstname">Nom de la catégorie :</label>
          <input
            type="text"
            id="firstname"
            name="name"
            placeholder="Entrer le nom de la catégorie"
            value={updatedCategory.name}
            onChange={handleChangeNewCategory}
          />
        </div>
        <div className="formRow">
          <label htmlFor="color">
            Entrer l&apos;hexadécimal d&apos;une couleur :
          </label>
          <div
            className="colorPreview"
            style={{
              backgroundColor:
                updatedCategory.color.length === 6
                  ? `#${updatedCategory.color}`
                  : "",
            }}
          />
          <input
            type="text"
            id="color"
            name="color"
            maxLength="6"
            placeholder="Entrer un code à 6 chiffres"
            value={updatedCategory.color}
            onChange={handleChangeNewCategory}
          />
        </div>
        <button type="submit" className="submitButton">
          Modifier la catégorie
        </button>
        <p>{feedbackMessage}</p>
      </form>
    </div>
  );
};

ModifyCategoriesInfo.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ModifyCategoriesInfo;
