import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";
import logo from "../../../public/images/planete-sciences.png";
import "./Login.css";
import getAuthorizationHeaders from "../../services/getAuthorizationHeaders";

const API_URL = process.env.REACT_APP_API_URL;

// const API_URL = "http://localhost:8000";

function Login({ setLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const headers = getAuthorizationHeaders();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email || !password) {
      alert("Veuillez remplir tous les champs");
    } else {
      axios
        .post(`${API_URL}/api/administrateurs/login`, { email, password }, headers)
        .then((res) => res.data)
        .then((data) => {
          localStorage.setItem("TOKEN", data.token);
          setLogin(true);
          history.pushState("/admin");
        })
        .catch((err) => {
          if (axios.isAxiosError(err) && 'errorMessage' in err.response.data) {
            alert(err.response.data.errorMessage);
          }
        });
    }
  };

  return (
    <div>

      <div className="login-div">
        <form className="login" onSubmit={handleSubmit}>
          <div className="d-md-none">
            Pour vous connecter à l'interface d'administration,
            Veuillez utiliser un ordinateur.
          </div>
          <div className="d-none d-md-block">
            <img src={logo} alt="planete science" />
            <div className="login-input">
              <div className="login-email">
                <label htmlFor="login-email">
                  Email :{" "}
                  <input
                    type="email"
                    name="email"
                    id="login-email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </label>
              </div>
              <div className="login-password">
                <label html="password">
                  Password :{" "}
                  <input
                    type="password"
                    name="password"
                    id="login-password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <button type="submit" className="login-button p-1">
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

Login.propTypes = {
  setLogin: PropTypes.func.isRequired,
};

export default Login;
