import React from "react";
import { Helmet } from "react-helmet";
import Menu from "../../user/Menu";
import Footer from "../../user/Footer";
import SousMenuEvenement from "./SousMenuEvenement";
import AffichagePart from "../../admin/partenaires/AffichagePart/AffichagePartenaires";
import "./SousMenuEvenement.css";

function PageSousMenuEvenement() {
  return (
    <div className="accueil">
      <Helmet>
        <title>Évènements - Planète Sciences Sarthe</title>
      </Helmet>
      <Menu />
      <SousMenuEvenement />
      <div className="NosPartenairesD">
        <h2 className="NosPartenaires">Nos partenaires</h2>
      </div>
      <AffichagePart />
      <Footer />
    </div>
  );
}

export default PageSousMenuEvenement;
