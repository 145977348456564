/* eslint-disable prefer-const */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import "../partenaires/uploadFile/UploadFile.css";
import PropTypes from "prop-types";
import axios from "axios";
import $ from 'jquery';
import getAuthorizationHeaders from "../../services/getAuthorizationHeaders";

const API_URL = process.env.REACT_APP_API_URL;

function UploadFile() {

  const [filename, setFilename] = useState("test.png");

  const [tropheeAdd, setTropheeAdd] = useState({
    media_id: "",
    media: [
      {
        name: filename,
        type: "image",
      },
    ],
  });

  function moveFile(selectedFile) {
    const headers = getAuthorizationHeaders();
    const data = new FormData();
    data.append("file", selectedFile);
    // console.log(data);
    axios
      .post(`${API_URL}/api/uploadGallerie`, data, headers)
      .then((res) => res.data)
      .then((res) => {
        setFilename(res.filename);
        // window.location.reload();
        let type = selectedFile.type;
        // console.log(type);
        if (type.startsWith("video")) {
          $("#videoBtn").click()
        } else if (type.startsWith("image")) {
          $("#imageBtn").click()
        } else if (type.startsWith("application/pdf")) {
          $("#pdfBtn").click()
        }

      })
      .catch((err) => {
        alert(err);
      });
  };

  function ajouterPhoto(selectorFiles) {

    // insert into dans la bdd
    // lié au post
    let filenameGallerie = selectorFiles[0].name;
    // console.log(selectorFiles[0])
    // console.log(selectorFiles[0].type);
    let type = selectorFiles[0].type;
    if (type.startsWith("video")) {
      type = "video";
    } else if (type.startsWith("image")) {
      type = "image";
    } else if (type.startsWith("application/pdf")) {
      type = "pdf";
    } else {
      alert("Seul les images et les vidéos sont autorisées");
    }
    const gallerie = tropheeAdd;
    gallerie.media = {
      name: filenameGallerie,
      alt: filenameGallerie,
      type: type,
    };
    axios
      .post(`${API_URL}/api/gallerie/`, gallerie)
      .then(() => {
        window.alert("Le fichier a bien été ajouté")
        moveFile(selectorFiles[0]);
      }
      )
      .catch((err) => {
        window.alert(err);
      });

  };

  return (
    <div className="AddAdminUpload d-flex justify-content-center">
      <input type="file" onChange={(e) => ajouterPhoto(e.target.files)} />
    </div>
  );
}

export default UploadFile;
