import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";

import Trophee from "./components/trophee/Trophee";
import sousMenuEvenements from "./components/SousMenuEvenement/PageSousMenuEvenement";
import SousMenuActivites from "./components/SousMenuActivités/PageSousMenuActivités";
import sousMenuGalerie from "./components/SousMenuGalerie/PageSousMenuGalerie";
import sousMenuEvenementsTwo from "./components/SousMenuEvenement/Evenement2/PageEvent2";
import formContact from "./components/FormContact";
import Admin from "./admin/AdminAPP";
import Accueil from "./components/Accueil/Accueil";
import GaleriePhoto from "./galerie/GaleriePhoto";
import GalerieArticle from "./galerie/GalerieArticle";
import GalerieVideo from "./galerie/GalerieVideo";
import QuiSommesNous from "./qui-sommes-nous/QuiSommesNous";
import MentionsLegales from "./mentions-legales/MentionsLegales";
import PageEvenement from "./user/Evenements/PageEvenement";
import { pageActivités } from "./user/Activités/pageActivités";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Accueil} />
      <Route path="/évenements" component={sousMenuEvenements} />
      <Route path="/admin" component={Admin} />
      <Route path="/activités" component={SousMenuActivites} />
      <Route path="/page-activité/:id" component={pageActivités} />
      <Route path="/galerie" component={sousMenuGalerie} />
      <Route path="/événements2" component={sousMenuEvenementsTwo} />
      <Route path="/form" component={formContact} />
      <Route path="/trophées" component={Trophee} />
      <Route path="/galerie-photo" component={GaleriePhoto} />
      <Route path="/galerie-article" component={GalerieArticle} />
      <Route path="/galerie-video" component={GalerieVideo} />
      <Route path="/qui-sommes-nous" component={QuiSommesNous} />
      <Route path="/mentions-legales" component={MentionsLegales} />
      <Route path="/article/:id" component={PageEvenement} />
    </Switch>
  );
}

export default App;
