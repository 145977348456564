import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Menu from "../Menu";
import Footer from "../Footer";
import AffichagePart from "../../admin/partenaires/AffichagePart/AffichagePartenaires";
import "./sectionAnimation.css";
import { SectionAnimation } from "./SectionAnimation";

const API_URL = process.env.REACT_APP_API_URL;

// customHook to use setInterval
function useInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    const id = setInterval(tick, 3000);
    return () => clearInterval(id);
  }, [delay]);
}

export const pageActivités = (props) => {
  const { id } = props.match.params;
  const [activité, setActivité] = useState({
    media: [{ name: "loading-buffering.gif", type: "backgroundImage" }],
    title: "loading-buffering.gif",
    texts: [{ content: "loading", type: "paragraphe" }],
    animations: [
      {
        media: [
          {
            name: "loading-buffering.gif",
            alt: "",
          },
        ],
      },
    ],
  });
  const [animation, setAnimation] = useState({ id: 0 });
  // selectedIndex is for the positions of the diaporama
  const [selectedIndex, setselectedIndex] = useState({
    previous1: 3,
    previous2: 4,
    current: 0,
    next1: 1,
    next2: 2,
  });
  const [aLaLigne, setALaLigne] = useState(0);
  let charCount = [0, 0];
  useEffect(() => {
    axios
      .get(`${API_URL}/api/en_tete/${id}`)
      .then((res) => setActivité(res.data));
  }, [aLaLigne]);
  useEffect(() => {
    activité.texts.forEach((text, index) => {
      if (charCount[0] <= 1000) {
        charCount = [text.content.length + charCount[0], index];
        if (index === activité.texts.length - 1) {
          setALaLigne(100);
        }
      } else {
        setALaLigne(charCount[1]);
      }
    });
  }, [activité]);
  const determineClass = (selectedI, cardIndex) => {
    if (selectedI.current === cardIndex) {
      return "active";
    }
    if (selectedI.next1 === cardIndex) {
      return "next1";
    }
    if (selectedI.next2 === cardIndex) {
      return "next2";
    }
    if (selectedI.previous1 === cardIndex) {
      return "previous1";
    }
    if (selectedI.previous2 === cardIndex) {
      return "previous2";
    }
    return "hidden";
  };
  // function that moves images of the diaporama
  const handleNext = () => {
    setselectedIndex({
      previous1:
        selectedIndex.previous1 === 0 ? 4 : selectedIndex.previous1 - 1,
      previous2:
        selectedIndex.previous2 === 0 ? 4 : selectedIndex.previous2 - 1,
      current: selectedIndex.current === 0 ? 4 : selectedIndex.current - 1,
      next1: selectedIndex.next1 === 0 ? 4 : selectedIndex.next1 - 1,
      next2: selectedIndex.next2 === 0 ? 4 : selectedIndex.next2 - 1,
    });
  };
  // custom hooks to move images every 3s
  useInterval(handleNext, 3000);
  // afficher l'animation détaillée
  const handleClickAnimation = (index) => {
    const chosenId = activité.animations[index].id;
    axios
      .get(`${API_URL}/api/articles/${chosenId}`)
      .then((res) => setAnimation(res.data))
      .catch((err) => console.error(err));
  };

  return (
    <div className="pageContainer">
      <Helmet>
        <title>Activités - Planète Sciences Sarthe</title>
      </Helmet>
      <Menu />
      <header>
        <img
          src={`${API_URL}/Images/${activité.media.filter(
            (medium) => medium.type === "backgroundImage"
          )[0].name
            }`}
          alt={
            activité.media.filter(
              (medium) => medium.type === "backgroundImage"
            )[0].alt
          }
        />
        <h1 className="pageTitle">{activité.title}</h1>
      </header>
      <main
        className="pageBody pageBodyActivity"
        style={{ marginBottom: aLaLigne === 0 ? "70px" : "0" }}
      >
        <section className="pagePreviewOfImages">
          {activité.media
            .filter((elt, index) => index > 0 && index < 6)
            .map((image, index) => (
              <button
                key={image.id}
                type="button"
                className={`btn-OurReco ${determineClass(
                  selectedIndex,
                  index
                )}`}
              >
                <img src={`${API_URL}/images/${image.name}`} alt={image.alt} />
              </button>
            ))}
        </section>
        <section className="textSection">
          {activité.texts
            .filter((t, index) => index <= aLaLigne)
            .map((text) => {
              return text.type === "h1" ? (
                <h1 key={text.content}>{text.content}</h1>
              ) : text.type === "h2" ? (
                <h2 key={text.content}>{text.content}</h2>
              ) : (
                <div key={text.content}>
                  <p >{text.content}</p> <br />{" "}
                </div>
              );
            })
          }
        </section>
      </main>
      <section
        className="textSection secondBody"
        style={{ marginBottom: aLaLigne > 0 ? "70px" : "0" }}
      >
        {activité.texts
          .filter((t, index) => index > aLaLigne)
          .map((text) => {
            return text.type === "h1" ? (
              <h1 key={text.id}>{text.content}</h1>
            ) : text.type === "h2" ? (
              <h2 key={text.id}>{text.content}</h2>
            ) : (
              <div key={text.content}>
                <p key={text.id}>{text.content}</p> <br />{" "}
              </div>
            );
          })}
      </section>
      <div className="animationList">
        {activité.animations.map((anim, index) => {
          return (
            <div
              key={index}
              className="animationCard"
              onClick={() => handleClickAnimation(index)}
            >
              <h3>{anim.title}</h3>
              <img
                src={`${API_URL}/images/${anim.media[0].name}`}
                alt={anim.media[0].alt}
              />
            </div>
          )
        })}
      </div>
      {animation.id === 0 ? "" : <SectionAnimation animation={animation} />}
      <div className="NosPartenairesD">
        <h2>Nos partenaires</h2>
      </div>
      <AffichagePart />
      <Footer />
    </div>
  );
};
