import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./AdminNous.css";

import getAuthorizationHeaders from "../../services/getAuthorizationHeaders";

const API_URL = process.env.REACT_APP_API_URL;

function AdminNous({ removeTokenAndLogout }) {
  const [isLoading, setIsLoading] = useState(true);
  const [paragraphOne, setParagraphOne] = useState();
  const [paragraphTwo, setParagraphTwo] = useState();
  const [paragraphThree, setParagraphThree] = useState();
  const [phonePs, setPhonePs] = useState();
  const [addressPs, setAddressPs] = useState();
  const [email, setEmail] = useState();

  const nousInfo = () => {
    axios.get(`${API_URL}/api/nous`).then((res) => {
      setParagraphOne(res.data.paragraphe1);
      setParagraphTwo(res.data.paragraphe2);
      setParagraphThree(res.data.paragraphe3);
      setPhonePs(res.data.phone);
      setAddressPs(res.data.address);
      setEmail(res.data.email);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    nousInfo();
  }, []);

  const handleClick = () => {
    const data = {
      id: 1,
      paragraphe1: paragraphOne,
      paragraphe2: paragraphTwo,
      paragraphe3: paragraphThree,
      phone: phonePs,
      address: addressPs,
      email,
    };
    const headers = getAuthorizationHeaders();

    axios
      .put(`${API_URL}/api/nous`, data, headers)
      .then((res) => res.data)
      .then(() => alert("Modifications sauvegardées"))
      .catch((err) => {
        if (err.response.status !== 401) {
          alert(err.response.data.errorMessage);
        } else {
          alert("Vous devez vous reconnecter");
          removeTokenAndLogout();
        }
      });
  };

  if (isLoading) {
  return (
    <div className="py-5 d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
  }

  return (
    <div className="AdminComp">
      <h1 className="Modifnous">
        Modifier les paragraphes présents sur la page &quot;Qui
        sommes-nous&quot;
      </h1>
      <div className="adminNous-paragraph">
        <div className="paragrapheNous">
          <p className="titreNous">1er paragraphe</p>
          <textarea
            type="text"
            name="paragraphOne"
            className="adminNous-text"
            value={paragraphOne}
            onChange={(e) => setParagraphOne(e.target.value)}
          >
            {paragraphOne}
          </textarea>
        </div>
        <div className="paragrapheNous">
          <p className="titreNous">2nd paragraphe</p>
          <textarea
            type="text"
            name="paragraphTwo"
            className="adminNous-text"
            value={paragraphTwo}
            onChange={(e) => setParagraphTwo(e.target.value)}
          >
            {paragraphTwo}
          </textarea>
        </div>
        <div className="paragrapheNous">
          <p className="titreNous">3eme paragraphe</p>
          <textarea
            type="text"
            name="paragraphThree"
            className="adminNous-text"
            value={paragraphThree}
            onChange={(e) => setParagraphThree(e.target.value)}
          >
            {paragraphThree}
          </textarea>
        </div>
      </div>
      <div className="adminNous-coordinates">
        {" "}
        <h2>Coordonnées</h2>
        <div className="adminNous-input">
          <div className="adminNous-coordinate">
            <label className="adminNous-data" htmlFor="phone">
              Téléphone :
            </label>
            <input
              className="nous-coordinate"
              type="tel"
              value={phonePs}
              onChange={(e) => setPhonePs(e.target.value)}
            />
          </div>
          <div className="adminNous-coordinate">
            <label type="text" className="adminNous-data">
              Adresse :
            </label>
            <input
              className="nous-coordinate"
              value={addressPs}
              onChange={(e) => setAddressPs(e.target.value)}
            />
          </div>
          <div className="adminNous-coordinate">
            <label type="email" className="adminNous-data">
              Email :
            </label>
            <input
              className="nous-coordinate"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="adminNous-div-button">
        <button
          className="adminNous-button"
          type="button"
          onClick={handleClick}
        >
          Modifier
        </button>
      </div>
    </div>
  );
}

AdminNous.propTypes = {
  removeTokenAndLogout: PropTypes.func.isRequired,
};

export default AdminNous;
