import React, { useState, useEffect } from "react";
import axios from "axios";
import AddCategoriesSection from "./AddCategoriesSection";
import AddImagesSection from "./AddImagesSection";
import AddTextsSection from "./AddTextsSection";
import SectionActivities from "./SectionActivities";

const API_URL = process.env.REACT_APP_API_URL;

const AddAnimationForm = () => {
  const [values, setValues] = useState({
    type: "animation",
    date: "",
    title: "",
    subtitle: "",
    id_entete: null,
    texts: [],
    media: [],
    categories: [],
    new_categories: [],
  });
  const [existingCategories, setExistingCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: "", color: "" });
  const [chosenCategories, setChosenCategories] = useState([]);
  const [newCategoryFormIsDisplayed, setNewCategoryFormIsDisplayed] = useState(
    false
  );
  const [filename, setFilename] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  useEffect(() => {
    axios
      .get(`${API_URL}/api/categories`)
      .then((res) => setExistingCategories(res.data));
    const today = new Date();
    setValues({
      ...values,
      date: `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`,
    });
  }, [values.texts]);
  useEffect(() => {
    if (filename !== "") {
      const updatedMediaArray = values.media;
      let newMedium;
      if (values.media.length > 0) {
        newMedium = { media_name: filename, alt: "", type: "image" };
      } else {
        newMedium = { media_name: filename, alt: "", type: "backgroundImage" };
      }
      updatedMediaArray.push(newMedium);
      setValues({ ...values, media: updatedMediaArray });
      setFilename("");
    }
  }, [filename]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmitAnimation = () => {
    if (
      values.title !== "" &&
      values.id_entete > 0 &&
      values.texts.length > 0 &&
      values.media.length > 0
    ) {
      axios
        .post(`${API_URL}/api/articles`, values)
        .then(() => {
          setFeedbackMessage("L'animation a bien été créée");
        })
        .catch((err) => {
          alert(err);
          console.error(err);
        });
    } else {
      setFeedbackMessage(
        "Veuillez remplir tous les champs, et ajouter au moins une image et un texte"
      );
    }
  };
  return (
    <main className="AdminComp addAnimationForm">
      <input
        type="text"
        name="title"
        className="mainTitle"
        placeholder="Saisir le titre de l'animation"
        value={values.title}
        onChange={handleChange}
      />
      <div className="alignLeft">
        <input
          type="text"
          name="subtitle"
          className="subtitle"
          placeholder="Sous-titre de l'expérience (pas obligatoire)"
          value={values.subtitle}
          onChange={handleChange}
        />
        <AddCategoriesSection
          existingCategories={existingCategories}
          newCategory={newCategory}
          chosenCategories={chosenCategories}
          newCategoryFormIsDisplayed={newCategoryFormIsDisplayed}
          values={values}
          setNewCategoryFormIsDisplayed={setNewCategoryFormIsDisplayed}
          setChosenCategories={setChosenCategories}
          setValues={setValues}
          setNewCategory={setNewCategory}
        />
        <AddImagesSection
          setFilename={setFilename}
          setValues={setValues}
          values={values}
          type="animation"
        />
        <AddTextsSection setValues={setValues} values={values} />
        <SectionActivities setValues={setValues} values={values} />
      </div>
      <button
        type="button"
        onClick={handleSubmitAnimation}
        className="addTextButton"
      >
        Créer l&apos;animation
      </button>
      <p className="feedback">{feedbackMessage}</p>
    </main>
  );
};

export default AddAnimationForm;
