import React from "react";
import PropTypes from "prop-types";
import PartenaireCard from "./PartenaireCard";
import "../AdminAPP.css";

function ListPartenaires({ partenaires, deleteClick }) {
  return (
    <div className="Card">
      {partenaires.map((card) => (
        <PartenaireCard
          name={card.name}
          mediaName={card.media_name}
          mediaAlt={card.media_alt}
          id={card.id}
          deleteClick={deleteClick}
          key={card.id}
        />
      ))}
    </div>
  );
}

ListPartenaires.propTypes = {
  partenaires: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      media_id: PropTypes.number,
      media_name: PropTypes.string,
      media_alt: PropTypes.string,
    })
  ).isRequired,
  deleteClick: PropTypes.func.isRequired,
};

export default ListPartenaires;
