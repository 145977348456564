import axios from "axios";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ExistingAnimation from "./ExistingAnimation";

const API_URL = process.env.REACT_APP_API_URL;

const ListOfAnimations = ({ type }) => {
  const [animations, setAnimations] = useState([]);

  useEffect(() => {
    const url = `${API_URL}/api/articles?type=${type}`;
    axios.get(url).then((res) => {
      setAnimations(res.data);
    });
  }, []);

  const handleClickDelete = (id) => {
    const confirmation = confirm("Êtes-vous sûr de vouloir supprimer cette animation ?");

    if (confirmation) {
      axios.delete(`${API_URL}/api/articles/${id}`).then((res) => {
        window.location.reload();
      });
    }
  };
  return (
    <div className="listOfAnimations">
      {animations.map((animation) => {
        return (
          <ExistingAnimation
            key={animation.id}
            id={animation.id}
            title={animation.title}
            subtitle={animation.subtitle}
            date={animation.date}
            backgroundImage={animation.media[0]}
            handleClickDelete={handleClickDelete}
          />
        );
      })}
    </div>
  );
};

ListOfAnimations.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ListOfAnimations;
