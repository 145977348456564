import React from "react";
import PropTypes from "prop-types";
import arrayMove from "array-move";
import plus from "../../../public/images/plus.png";
import deleteIcon from "../../../public/images/delete.svg";
import upArrow from "../../../public/images/up-arrow.svg";

// composant InputTitle qu'on appelle plusieurs fois dans le composant suivant
const InputTitle = ({ values, type, index, setValues }) => {
  const handleChange = (e) => {
    setValues(() => {
      const updatedTextArray = values.texts;
      updatedTextArray[index].content = e.target.value;
      return {
        ...values,
        texts: updatedTextArray,
      };
    });
  };
  return type === "paragraphe" ? (
    <textarea
      className={type}
      cols="30"
      rows="5"
      value={values.texts[index].content}
      onChange={handleChange}
      placeholder="Entrer un paragraphe"
    />
  ) : (
    <input
      type="text"
      className={type}
      value={values.texts[index].content}
      onChange={handleChange}
      placeholder={`Entrer un ${type === "h1" ? "titre" : "sous-titre"}`}
    />
  );
};

const AddTextsSection = ({ values, setValues }) => {
  const handleClickAddText = (type) => {
    const updatedTextArray = values.texts;
    if (type === "h1") {
      setValues(() => {
        updatedTextArray.push({
          type: "h1",
          content: "",
        });
        return {
          ...values,
          texts: updatedTextArray,
        };
      });
    } else if (type === "h2") {
      setValues(() => {
        updatedTextArray.push({
          type: "h2",
          content: "",
        });
        return {
          ...values,
          texts: updatedTextArray,
        };
      });
    } else if (type === "paragraphe") {
      setValues(() => {
        updatedTextArray.push({
          type: "paragraphe",
          content: "",
        });
        return {
          ...values,
          texts: updatedTextArray,
        };
      });
    }
  };
  const deleteChosenText = (index) => {
    setValues(() => {
      const updatedTextArray = values.texts;
      updatedTextArray.splice(index, 1);
      return {
        ...values,
        texts: updatedTextArray,
      };
    });
  };
  const moveAText = (direction, index) => {
    setValues(() => {
      const updatedTextArray = values.texts;
      arrayMove.mutate(
        updatedTextArray,
        index,
        direction === "up" ? index - 1 : index + 1
      );
      return {
        ...values,
        texts: updatedTextArray,
      };
    });
  };
  return (
    <section className="addCategories">
      <div className="addAnimationForm">
        <h2 className="tutoAdmin mb-5">Créer le contenu de l'article</h2>
      </div>
      <div className="textFormatSelection">
        <button
          type="button"
          className="addTextButton"
          onClick={() => handleClickAddText("h1")}
        >
          <strong> AJOUTER UN TITRE</strong>
          <img src={plus} alt="Icone Plus" className="addIcon" />
        </button>
        <button
          type="button"
          className="addTextButton"
          onClick={() => handleClickAddText("h2")}
        >
          <strong>Ajouter un sous-titre</strong>
          <img src={plus} alt="Icone Plus" className="addIcon" />
        </button>
        <button
          type="button"
          className="addTextButton"
          onClick={() => handleClickAddText("paragraphe")}
        >
          Ajouter un paragraphe
          <img src={plus} alt="Icone Plus" className="addIcon" />
        </button>
      </div>
      <div className="textsCreated">
        {values.texts.map((text, index) => (
          <div key={text.id} className="textsCreated">
            <InputTitle
              type={text.type}
              index={index}
              values={values}
              setValues={setValues}
            />
            <button
              type="button"
              className={`deleteCategory delete${text.type}`}
              onClick={() => deleteChosenText(index)}
            >
              <img src={deleteIcon} alt="supprimer la catégorie" />
            </button>
            <div className={`up-and-down uad-${text.type}`}>
              <button type="button" onClick={() => moveAText("up", index)}>
                <img src={upArrow} alt="Up Icon" />
              </button>
              <button
                type="button"
                className="down-btn"
                onClick={() => moveAText("down", index)}
              >
                <img src={upArrow} alt="Up Icon" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

AddTextsSection.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.number),
    new_categories: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

InputTitle.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.number),
    new_categories: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default AddTextsSection;
