import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import facebookLogo from "../../public/images/facebook_logo.png";

function Footer() {
  const resSociaux = [
    {
      id: 1,
      img:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1200px-Instagram_logo_2016.svg.png",
      lien: "https://www.instagram.com/planete_sciences72/",
    },
    {
      id: 2,
      img: facebookLogo,
      lien: "https://www.facebook.com/planete.sciences.sarthe",
    },
    {
      id: 3,
      img: "https://upload.wikimedia.org/wikipedia/fr/c/c8/Twitter_Bird.svg",
      lien: "https://twitter.com/SciencesSarthe",
    },
  ];

  return (
    <div className="footer">
      <NavLink to="/mentions-legales">
        <img
          alt="copyright"
          className="copyrightimg"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/All_rights_reserved_logo.svg/1200px-All_rights_reserved_logo.svg.png"
        />
      </NavLink>
      <div className="res">
        {resSociaux.map((res) => (
          <li key={res.id}>
            <a href={res.lien} target="_blank" rel="noreferrer">
              <img alt="icons" src={res.img} className="resimage" />
            </a>
          </li>
        ))}
      </div>
    </div>
  );
}

export default Footer;
