import React from "react";
import PropTypes from "prop-types";
import suppr from "../../../public/images/suppr.png";
import "../AdminAPP.css";

const API_URL = process.env.REACT_APP_API_URL;

function PartenaireCard({ name, mediaAlt, mediaName, id, deleteClick }) {
  return (
    <div className="partenaires">
      <div className="TopPart">
        <h1 className="NomPart"> {name} </h1>
        <div className="DivIcon">
          <img
            className="LogoIcon"
            alt="logoSuppr"
            src={suppr}
            onClick={() => deleteClick(id)}
          />
        </div>
      </div>
      <div className="LogoPart">
        <img alt={mediaAlt} src={`${API_URL}/images/${mediaName}`} />
      </div>
    </div>
  );
}

PartenaireCard.propTypes = {
  name: PropTypes.string.isRequired,
  mediaAlt: PropTypes.string.isRequired,
  mediaName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  deleteClick: PropTypes.func.isRequired,
};

export default PartenaireCard;
