/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
// import { Document, Page } from 'react-pdf';

const API_URL = process.env.REACT_APP_API_URL;

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
}

function Galerie(props) {
  const [medias, setMedias] = useState([]);
  const { type } = props;
  const url = `${API_URL}/api/gallerie/imagevid/`;
  const urlFront = `${API_URL}/images/`;

  let containerSize = "row d-flex justify-content-center";

  if (window.location.pathname == "/galerie-article") {
    containerSize += " w-100";
  }

  useEffect(() => {
    // axios.get(`${API_URL}/api/media?type=${type}`).then((res) => {
    let isImage = null;
    if (window.location.pathname == "/galerie-photo") {
      isImage = 1;
    } else if (window.location.pathname == "/galerie-video") {
      isImage = 0;
    }
    else if (window.location.pathname == "/galerie-article") {
      isImage = 2;
    }
    axios.get(`${url}${isImage}`).then((res) => {
      setMedias(res.data);
    });
  }, []);

  return (
    <div className="galery container-fluid">
      <div className="row w-100 stickyBackGallery">
        <a className="text-white textDecoNone btn mt-4 mb-4 btnRadius ml-2" href="/galerie">
          Retour
        </a>
      </div>
      <div className={containerSize}>
        {medias.map((media) => {
          let css = "";
          if (window.location.pathname === "/galerie-photo") {
            css = "d-flex justify-content-center";
          }
          if (window.location.pathname === "/galerie-article") {
            css = "col-xl-5 col-xxl-4 mb-5";
          }
          return (
            <div className={css} key={media}>
              {window.location.pathname == "/galerie-photo" &&
                <img
                  src={urlFront + media.name}
                  alt={media.alt}
                  className="galery-media mb-3"
                />
              }
              {window.location.pathname == "/galerie-video" &&
                <div className="col-4">
                  <video className="videoSize" controls width="600" height="400">
                    <source src={`${API_URL}/videos/` + media.name}></source>
                    Désolé votre navigateur ne supporte pas les vidéos intégrées
                  </video>
                </div>
              }
              {window.location.pathname == "/galerie-article" &&
                <div>
                  {/* <Document
                    file="somefile.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>Page {pageNumber} of {numPages}</p> */}
                  <div className="d-flex justify-content-center">
                    <a href={`${API_URL}/Images/${media.name}`} target="_blank">
                      <div>
                        <button className="btn colorVoirDocu mb-1">
                          Voir le document en grand
                        </button>
                      </div>
                    </a>
                  </div>
                  <div>
                    <embed
                      type="application/pdf"
                      target="_top"
                      src={`${API_URL}/Images/${media.name}#toolbar=0&navpanes=0&scrollbar=0&#view=Fith`}
                      alt={media.alt}
                      className="pdfStyleFront"
                    />
                  </div>
                </div>
              }

            </div>
          )
        })}
      </div>
    </div>
  );
}

Galerie.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Galerie;
