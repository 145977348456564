import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import "./pageArticle.css";
import axios from "axios";
import Menu from "../Menu";
import Footer from "../Footer";
import AffichagePart from "../../admin/partenaires/AffichagePart/AffichagePartenaires";


const API_URL = process.env.REACT_APP_API_URL;

// customHook to use setInterval
function useInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    const id = setInterval(tick, 3000);
    return () => clearInterval(id);
  }, [delay]);
}

const PageEvenement = ({ match }) => {
  const { id } = match.params;
  const [article, setarticle] = useState({
    media: [{ name: "loading", type: "backgroundImage" }],
    title: "loading",
    texts: [{ content: "loading", type: "paragraphe" }],
    categories: [],
    date: "2020",
  });
  // selectedIndex is for the positions of the diaporama
  const [selectedIndex, setselectedIndex] = useState({
    previous1: 3,
    previous2: 4,
    current: 0,
    next1: 1,
    next2: 2,
  });
  // aLaLigne is used to manage long article of >1.1k characters
  const [aLaLigne, setALaLigne] = useState(0);
  let charCount = [0, 0];
  useEffect(() => {
    axios
      .get(`${API_URL}/api/articles/${id}`)
      .then((res) => setarticle(res.data));
  }, [aLaLigne]);
  // useEffect to set when the texts need to go under the diaporama
  useEffect(() => {
    article.texts.forEach((text, index) => {
      if (charCount[0] <= 1000) {
        charCount = [text.content.length + charCount[0], index];
        if (index === article.texts.length - 1) {
          setALaLigne(100);
        }
      } else {
        setALaLigne(charCount[1]);
      }
    });
  }, [article]);
  // function to apply the good className to the images in the diaporama
  const determineClass = (selectedI, cardIndex) => {
    if (selectedI.current === cardIndex) {
      return "active";
    }
    if (selectedI.next1 === cardIndex) {
      return "next1";
    }
    if (selectedI.next2 === cardIndex) {
      return "next2";
    }
    if (selectedI.previous1 === cardIndex) {
      return "previous1";
    }
    if (selectedI.previous2 === cardIndex) {
      return "previous2";
    }
    return "hidden";
  };
  // function that moves images of the diaporama
  const handleNext = () => {
    setselectedIndex({
      previous1:
        selectedIndex.previous1 === 0 ? 4 : selectedIndex.previous1 - 1,
      previous2:
        selectedIndex.previous2 === 0 ? 4 : selectedIndex.previous2 - 1,
      current: selectedIndex.current === 0 ? 4 : selectedIndex.current - 1,
      next1: selectedIndex.next1 === 0 ? 4 : selectedIndex.next1 - 1,
      next2: selectedIndex.next2 === 0 ? 4 : selectedIndex.next2 - 1,
    });
  };
  // custom hooks to move images every 3s
  useInterval(handleNext, 3000);
  return (
    <div className="pageContainer">
      <Helmet>
        <title>Évènements - Planète Sciences Sarthe</title>
      </Helmet>
      <Menu />
      <header>
        <img
          src={`${API_URL}/images/${article.media.filter(
            (medium) => medium.type === "backgroundImage"
          )[0].name
            }`}
          alt={
            article.media.filter(
              (medium) => medium.type === "backgroundImage"
            )[0].alt
          }
        />
        <h1 className="pageTitle">{article.title}</h1>
      </header>
      <h2 className="text-center mt-5">{article.subtitle}</h2>
      <h3>Article écrit le {article.date}</h3>
      <section className="categoriesContainer">
        {article.categories.map((category) => (
          <div
            key={category.id}
            className="buttonCategory"
            style={{ backgroundColor: `#${category.color}` }}
          >
            {category.name.toUpperCase()}
          </div>
        ))}
      </section>
      <main
        className="pageBody"
        style={{ marginBottom: aLaLigne === 0 ? "70px" : "0" }}
      >
        <section className="pagePreviewOfImages">
          {article.media
            .filter((elt, index) => index > 0 && index < 6)
            .map((image, index) => (
              <button
                key={image.id}
                type="button"
                className={`btn-OurReco ${determineClass(
                  selectedIndex,
                  index
                )}`}
              >
                <img src={`${API_URL}/images/${image.name}`} alt={image.alt} />
              </button>
            ))}
        </section>
        <section className="textSection">
          {article.texts
            .filter((t, index) => index <= aLaLigne)
            .map((text) => {
              return text.type === "h1" ? (
                <h1 key={text.id}>{text.content}</h1>
              ) : text.type === "h2" ? (
                <h2 key={text.id}>{text.content}</h2>
              ) : (
                <>
                  <p key={text.id}>{text.content}</p> <br />{" "}
                </>
              );
            })}
        </section>
      </main>
      <section
        className="textSection secondBody"
        style={{ marginBottom: aLaLigne > 0 ? "70px" : "0" }}
      >
        {article.texts
          .filter((t, index) => index > aLaLigne)
          .map((text) => {
            return text.type === "h1" ? (
              <h1 key={text.id}>{text.content}</h1>
            ) : text.type === "h2" ? (
              <h2 key={text.id}>{text.content}</h2>
            ) : (
              <>
                <p key={text.id}>{text.content}</p> <br />{" "}
              </>
            );
          })}
      </section>
      <div className="NosPartenairesD">
        <h2>Nos partenaires</h2>
      </div>
      <AffichagePart />
      <Footer />
    </div>
  );
};

PageEvenement.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default PageEvenement;
