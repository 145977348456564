import React from "react";
import "./adminAnimation.css";
import AddHeader from "../AddHeader";
import ListOfAnimations from "./ListOfAnimations";

function AdminAnimation() {
  return (
    <div className="AdminComp">
      <AddHeader text="une nouvelle animation" path="add-animations" />
      <ListOfAnimations type="animation" />
    </div>
  );
}

export default AdminAnimation;
