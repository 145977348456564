import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const SectionActivities = ({ values, setValues }) => {
  const [entete, setEntete] = useState([]);
  let path = window.location.pathname;
  //cacher tout ce component si on est sur modify (=bug et pas lieu d'être,
  // on peut pas modifier une animation tout en l'assignant ailleurs)
  let css = "";
  if (path.includes("modify-animations")) {
    css = "d-none";
  } else {
    css = "";
  }
  useEffect(() => {
    axios
      .get(`${API_URL}/api/en_tete`)
      .then((res) => setEntete(res.data))
      .catch((err) => console.error(err));
  }, []);
  const handleChangeRadio = (e) => {
    const id = parseInt(e.target.id, 10);
    setValues({ ...values, id_entete: id });
  };
  return (
    <div className={css}>
      <section className="addCategories">
        <div className="addAnimationForm ">
          <h2 className="tutoAdmin">Ajouter cette animation à l&apos;une de vos sections :</h2>
          <div className="alignLeft">
            {entete.map((ent) => (
              <label htmlFor={ent.id}>
                <input
                  type="radio"
                  name="activities"
                  id={ent.id}
                  onChange={handleChangeRadio}
                />
                {ent.title}
              </label>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

SectionActivities.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    texts: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.number),
    new_categories: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default SectionActivities;
