import React, { useState } from "react";
import PropTypes from "prop-types";
import UploadFile from "../partenaires/uploadFile/UploadFile";
import { Image } from "./Image";

const API_URL = process.env.REACT_APP_API_URL;
// composant utilisé dans le composant principal suivant
const InputAlt = ({ index, addedMedia, setAddedMedia }) => {
  const handleChangeAlt = (e) => {
    setAddedMedia(() => {
      const updatedMediaArray = addedMedia;
      updatedMediaArray[index].alt = e.target.value;
      return updatedMediaArray;
    });
  };
  return (
    <>
      {/* <label htmlFor="alt">Descriptif de cette image :</label>
      <input
        type="text"
        placeholder="descriptif de l'image"
        value={addedMedia[index].alt}
        onChange={handleChangeAlt}
      /> */}
    </>
  );
};

const ModifyImagesSection = ({
  setFilename,
  values,
  setType,
  addedMedia,
  setAddedMedia,
}) => {
  const [selectedFile, setselectedFile] = useState("");
  const handleBcgImage = (e) => {
    const { checked } = e.target;
    if (checked) {
      setType("backgroundImage");
    } else {
      setType("image");
    }
  };
  return (
    <section className="addCategories">
      <div className="addAnimationForm ">
        <h2 className="tutoAdmin">Ajouter des images</h2>
      </div>
      <div className="uploadPreview">
        <div className="uploadFormAnimations">
          <h5>Ajouter une image au diaporama :</h5>

          <label htmlFor="bcgImage">
            <input type="checkbox" id="bcgImage" onChange={handleBcgImage} />
            Changer l&apos;image principale
          </label>
          <UploadFile
            setselectedFile={setselectedFile}
            selectedFile={selectedFile}
            setFilename={setFilename}
          />
        </div>
        <Image medias={values.media} />
        {/* {values.media.length > 0 && (
          <img
            src={`${API_URL}/images/${
              values.media.filter((img) => img.type === "backgroundImage")[0]
                .name
            }`}
            alt="pas de fond"
          />
        )} */}
      </div>
      <div className="uploadPreview">
        {addedMedia.length > 0
          ? addedMedia.map((picture, index) => (
              <div className="imagesPreviewRow">
                <img
                  src={`${API_URL}/images/${picture.name}`}
                  alt={picture.alt}
                />
                <InputAlt
                  index={index}
                  addedMedia={addedMedia}
                  setAddedMedia={setAddedMedia}
                />
              </div>
            ))
          : ""}
      </div>
    </section>
  );
};

ModifyImagesSection.propTypes = {
  setFilename: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    texts: PropTypes.arrayOf(PropTypes.object),
    media: PropTypes.arrayOf(PropTypes.object),
    animations: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  setType: PropTypes.func.isRequired,
  addedMedia: PropTypes.arrayOf(PropTypes.object).isRequired,
  setAddedMedia: PropTypes.func.isRequired,
};

InputAlt.propTypes = {
  addedMedia: PropTypes.arrayOf(PropTypes.object).isRequired,
  setAddedMedia: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default ModifyImagesSection;
