import React, { useState } from "react";
import "./uploadFile/UploadFile.css";
import PropTypes from "prop-types";
import UploadFile from "./uploadFile/UploadFile";

const API_URL = process.env.REACT_APP_API_URL;

function AddPartenaireForm({ addPartenaire }) {
  const [selectedFile, setselectedFile] = useState("default");
  const [filename, setFilename] = useState("");
  const [alt, setAlt] = useState("");
  const [partenaireName, setPartenaireName] = useState("");

  const handleChangeAlt = (event) => {
    setAlt(event.target.value);
  };
  const handleChangePartenaire = (event) => {
    setPartenaireName(event.target.value);
  };

  function handleSubmit(event) {
    event.preventDefault();
    const data = {
      name: partenaireName,
      media: {
        media_name: filename,
        alt,
        type: "image",
      },
    };
    addPartenaire(data);
  }

  return (
    <form className="AjoutAdminForm" onSubmit={handleSubmit}>
      <h1 className="AddAdminTitre"> ajouter un nouveau partenaire </h1>
      <div className="testThib">
        <div className="AddAdminImg">
          <div className="AddAdminDescription">
            <label htmlFor="partenaire">Ajouter un nom</label>
            <input
              id="partenaire"
              type="text"
              name="partenaire"
              placeholder="nom du partenaire"
              value={partenaireName}
              onChange={handleChangePartenaire}
            />
          </div>
          <div className="AddAdminDescription">
            <label htmlFor="description">Ajouter une description</label>
            <input
              id="description"
              type="text"
              name="alt"
              placeholder="Description"
              value={alt}
              onChange={handleChangeAlt}
            />
          </div>
          <UploadFile
            setselectedFile={setselectedFile}
            setFilename={setFilename}
            selectedFile={selectedFile}
          />
          <button type="submit" className="mt-3 btn btn-info">
            {" "}
            Créer{" "}
          </button>
        </div>
        {filename && (
          <img
            src={`${API_URL}/images/${filename}`}
            alt="test"
            className="AffichageImg"
          />
        )}
      </div>
    </form>
  );
}

AddPartenaireForm.propTypes = {
  addPartenaire: PropTypes.func.isRequired,
};

export default AddPartenaireForm;
