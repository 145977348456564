import React from "react";
import AddHeader from "../AddHeader";
import ListOfAnimations from "../animation/ListOfAnimations";
import { NavLink } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

function AdminEvent() {
  return (
    <div className="AdminComp">
      <div className="col-4">
        <AddHeader text="un nouvel événement" path="add-events" />
      </div>
      <ListOfAnimations type="événement" />
      <NavLink className="btn btn-secondary btnRobotFromEvent" to={`/admin/trophees-admin`} >
        <h4 className="mt-1">Robotique/Trophées </h4>
        <img className="imageRobotEvent img-responsive" src={`${API_URL}/Images/Car-Plant.jpg`} alt="planete science robotique" />
      </NavLink>
    </div>
  );
}

export default AdminEvent;
