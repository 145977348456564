// import React, { useState, useEffect } from "react";
// import "./Galerie.css";
// import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL;

// function GalerieArticle() {
//   const [article, setArticle] = useState([]);

//   useEffect(() => {
//     axios.get(`${API_URL}/api/media?type=article`).then((res) => {
//       setArticle(res.data);
//     });
//   }, []);

//   return (
//     <div className="galery">
//       {article.map((media) => (
//         <div key={media}>
//           <embed
//             type="application/pdf"
//             target="_top"
//             src={`${API_URL}/images/${media.name}`}
//             alt={media.alt}
//             className="galery-media"
//           />
//         </div>
//       ))}
//     </div>
//   );
// }

// export default GalerieArticle;

import React from "react";
import { Helmet } from "react-helmet";
import Galerie from "./Galerie";
import "./Galerie.css";

function GalerieArticle() {
  return (
    <div className="galerie-photo">
      <Helmet>
        <title>Galeries - Planète Sciences Sarthe</title>
      </Helmet>
      <h1 className="text-center fw-bold mt-5">
        Articles
      </h1>
      <Galerie type="image" />
    </div>
  );
}

export default GalerieArticle;
