import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import AddCategoriesSection from "./AddCategoriesSection";
import AddTextsSection from "./AddTextsSection";
import ModifyImagesSection from "../activités/ModifyImagesSection";
import { DeleteImagesSection } from "../activités/DeleteImagesSection";
import DontForget from "../DontForget";
import SectionActivities from "./SectionActivities";

const API_URL = process.env.REACT_APP_API_URL;

const ModifyAnimationForm = ({ match }) => {
  const { id } = match.params;
  const [values, setValues] = useState({
    id: 0,
    type: "",
    date: "",
    title: "",
    subtitle: "",
    id_entete: null,
    texts: [],
    media: [],
    categories: [],
    new_categories: [],
  });
  const [existingCategories, setExistingCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: "", color: "" });
  const [chosenCategories, setChosenCategories] = useState([]);
  const [newCategoryFormIsDisplayed, setNewCategoryFormIsDisplayed] = useState(
    false
  );
  const [filename, setFilename] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackCategory, setFeedbackCategory] = useState("");
  const [feedbackImage, setfeedbackImage] = useState("");
  const [feedbackActivity, setfeedbackActivity] = useState("");
  const [addedMedia, setAddedMedia] = useState([]);
  const [type, setType] = useState("image");
  // on récupère les valeurs au chargement de la page
  useEffect(() => {
    axios.get(`${API_URL}/api/articles/${id}`).then((res) => {
      const animation = res.data;
      animation.new_categories = [];
      setValues(animation);
      setChosenCategories(res.data.categories);
    });
  }, []);
  // on récupère toutes les catégories existantes
  useEffect(() => {
    axios
      .get(`${API_URL}/api/categories`)
      .then((res) => setExistingCategories(res.data));
  }, [values.texts]);
  useEffect(() => {
    if (filename !== "") {
      const updatedMediaArray = addedMedia;
      const newMedium = { name: filename, alt: "", type };
      updatedMediaArray.push(newMedium);
      setAddedMedia(updatedMediaArray);
      setFilename("");
      document.getElementById("bcgImage").checked = false;
      setType("image");
    }
  }, [filename]);
  // gérer la modification du titre et des textes
  const handleSubmitTexts = () => {
    if (
      values.title !== "" &&
      values.texts.length > 0 &&
      values.subtitle !== "" &&
      values.date !== "" &&
      values.type !== ""
    ) {
      axios
        .put(`${API_URL}/api/articles/${id}`, {
          title: values.title,
          subtitle: values.subtitle,
          date: values.date,
          type: values.type,
        })
        .then(() => {
          setFeedbackMessage("Le contenu a bien été modifié");
        })
        .catch((err) => {
          alert(err);
          console.error(err);
        });
      axios
        .put(`${API_URL}/api/articles/${id}/texts`, values.texts)
        .catch((err) => alert(err));
    } else {
      setFeedbackMessage(
        "Veuillez ne pas laisser le titre vide et ajouter au moins un sous-titre"
      );
    }
  };
  // gérer la modification et l'ajout de catégories
  const handleSubmitCategories = () => {
    if (chosenCategories.length > 0) {
      axios
        .put(`${API_URL}/api/articles/${id}/categories`, chosenCategories)
        .then(() =>
          setFeedbackCategory("Les catégories ont bien été modifiées")
        )
        .catch((err) => {
          alert(err);
          console.error(err);
        });
    } else {
      setFeedbackCategory("Veuillez ajouter au moins une catégorie");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleSubmitMedia = () => {
    let type = addedMedia[0].type;
    if (addedMedia.length > 0) {
      axios
        .post(`${API_URL}/api/articles/${id}/media/${type}`, addedMedia)
        .then(() => setfeedbackImage("les images on bien été enregistrées"))
        .catch((err) => alert(err));
    } else {
      setfeedbackImage("Il n'y pas d'image à ajouter");
    }
    window.location.reload();
  };
  const handleSubmitNewActivity = () => {
    if (values.id_entete > 0) {
      axios
        .put(`${API_URL}/api/articles/${id}`, { id_entete: values.id_entete })
        .then(() => setfeedbackActivity("la page liée a bien été modifiée"))
        .catch((err) => alert(err));
    } else {
      setfeedbackActivity("Cochez une case avant de soumettre votre réponse");
    }
  };

  return (
    <main className="AdminComp addAnimationForm">
      <div className="mainTitle tutoAdmin">
        Modifier en cliquant sur le titre et sous-titre
      </div>
      <input
        type="text"
        name="title"
        className="mainTitle titreModify"
        placeholder="Saisir le titre de l'animation"
        value={values.title}
        onChange={handleChange}
      />
      <div className="alignLeft">
        <div className="d-flex justify-content-center">
          {/* <div className="sousTitre">
            Sous-titre :
          </div> */}
          <input
            type="text"
            name="subtitle"
            className="subtitle text-center mt-2"
            placeholder="Sous-titre de l'expérience (* obligatoire)"
            value={values.subtitle}
            onChange={handleChange}
          />
        </div>
        <AddTextsSection setValues={setValues} values={values} />
      </div>
      <button
        type="button"
        onClick={handleSubmitTexts}
        className="addTextButton"
      >
        Enregistrer la modification du titre et des textes
      </button>
      <p className="feedback">{feedbackMessage}</p>
      <div className="alignLeft">
        <AddCategoriesSection
          existingCategories={existingCategories}
          newCategory={newCategory}
          chosenCategories={chosenCategories}
          newCategoryFormIsDisplayed={newCategoryFormIsDisplayed}
          values={values}
          setNewCategoryFormIsDisplayed={setNewCategoryFormIsDisplayed}
          setChosenCategories={setChosenCategories}
          setValues={setValues}
          setNewCategory={setNewCategory}
        />
      </div>
      <button
        type="button"
        onClick={handleSubmitCategories}
        className="addTextButton"
      >
        Enregistrer la modification des catégories
      </button>
      <p className="feedback">{feedbackCategory}</p>
      <div className="alignLeft">
        <ModifyImagesSection
          setAddedMedia={setAddedMedia}
          values={values}
          setFilename={setFilename}
          setType={setType}
          addedMedia={addedMedia}
        />
      </div>
      <DontForget />
      <button
        type="button"
        onClick={handleSubmitMedia}
        className="addTextButton"
      >
        Enregistrer les modifications faites sur les images
      </button>
      <p className="feedback">{feedbackImage}</p>
      <div className="alignLeft">
        <DeleteImagesSection values={values} setValues={setValues} />
      </div>
      {values.type === "animation" ? (
        <>
          <div className="alignLeft">
            <SectionActivities setValues={setValues} values={values} />
          </div>
          <button
            type="button"
            onClick={handleSubmitNewActivity}
            className="addTextButton"
          >
            Enregistrer la modification de la page liée
          </button>
          <p className="feedback">{feedbackActivity}</p>
        </>
      ) : (
        ""
      )}
    </main>
  );
};

ModifyAnimationForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  }).isRequired,
};

export default ModifyAnimationForm;
