import React from "react";
import { Helmet } from "react-helmet";
import AffichagePart from "../../admin/partenaires/AffichagePart/AffichagePartenaires";
import SystèmePlanete from "./SystèmePlanete/SystèmePlanete";
import ovale from "../../../public/images/accueil/ovalegalactique.png";
import "./Accueil.css";
import Menu from "../../user/Menu";
import Footer from "../../user/Footer";

function Accueil() {
  return (
    <div className="accueil">
      <Helmet>
        <title>Accueil - Planète Sciences Sarthe</title>
      </Helmet>
      <Menu />
      <img src={ovale} alt="ovale" className="ovale" />
      <SystèmePlanete />
      <div className="NosPartenairesD">
        <h2 className="NosPartenaires">Nos partenaires</h2>
      </div>
      <AffichagePart />
      <Footer />
    </div>
  );
}

export default Accueil;
