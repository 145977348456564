/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

function DontForget() {

    return (
        <div className="text-danger mb-2">
            N'oubliez pas de cliquer sur "upload" avant de cliquer sur 'Créer/Modifier/Enregistrer'
        </div>
    );
}

export default DontForget;
