/* eslint-disable react/jsx-indent-props */
/* eslint-disable prettier/prettier */
import React from "react";

function Dons() {
    return (
        <div className="">
            <div htmlFor="don" className="label dons">
                <form action="https://www.paypal.com/donate" method="post" target="_top">
                    <input type="hidden" name="hosted_button_id" value="DRYUXQZ5KMKBN" />
                    <input type="image" src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Bouton Faites un don avec PayPal" />
                    <img alt="" border="0" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" width="1" height="1" />
                </form>
                {/* <input type="submit" className="button" id="don" value="Faire un Don" /> */}
            </div>

        </div>
    )
}

export default Dons;