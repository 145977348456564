import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./AffichagePart.css";

const API_URL = process.env.REACT_APP_API_URL;

function AffichagePartenaires({ partenaires }) {
  const [listPartenaires, setListPartenaires] = useState(partenaires || []);
  const url = `${API_URL}/api/partenaires`;
  useEffect(() => {
    if (
      listPartenaires.length === 0 ||
      listPartenaires.length !== partenaires.length
    ) {
      axios
        .get(url)
        .then((result) => result.data)
        .then((data) => {
          setListPartenaires(data);
        });
    }
  }, [partenaires]);

  return (
    <div className="ViewParts">
      <div className="space">
        {listPartenaires.map((partenaire) => (
          <img
            key={partenaire.id}
            src={`${API_URL}/images/${partenaire.media_name}`}
            alt={partenaire.media_alt}
            className="AffichageImg"
          />
        ))}
      </div>
    </div>
  );
}

AffichagePartenaires.propTypes = {
  partenaires: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      media_id: PropTypes.number,
      media_name: PropTypes.string,
      media_alt: PropTypes.string,
    })
  ),
};

AffichagePartenaires.defaultProps = {
  partenaires: [],
};

export default AffichagePartenaires;
